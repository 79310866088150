.select_wrapper {
  position: relative;
  width: 100%;
  height: 50px;
}

.select {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: var(--forms2_shadow);
  background-color: var(--forms_bgc_2);
  cursor: pointer;

  &.opened {
    .icon {
      transform: rotateZ(0deg);
    }
  }
}

.icon {
  flex: none;
  stroke: var(--main-text);
  transform: rotateZ(180deg);
  transition: transform 0.15s;
}

.placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  color: var(--transparent-color);

  &.selected {
    color: var(--main-text);
  }
}

.dropdown {
  position: absolute;
  z-index: 1;
  height: 0;
  overflow: hidden;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  transition: height 0.15s;
}

.service_item {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background-color: var(--select_option_bgc);
  }
}

@media screen and (min-width: 1550px) {
  .placeholder {
    font-size: 18px;
  }

  .service_item {
    font-size: 18px;
  }
}
