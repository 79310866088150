.modal {
  width: 90%;
  background-color: var(--main-bgc);

  @media screen and (min-width: 768px) {
    max-width: 625px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1077px;
  }
}

.head_row {
  width: 100%;
  display: flex;
  gap: 30px;
  padding: 15px 60px;
}

.table_head {
  font-size: 14px;
  color: var(--main-text);

  &:nth-child(1) {
    flex-basis: 13%;
  }
  &:nth-child(2) {
    flex-basis: 42%;
    word-wrap: break-word;
  }
  &:nth-child(3) {
    flex-basis: 30%;
    word-wrap: break-word;
  }
  &:nth-child(4) {
    flex-basis: 15%;
  }
}

.title {
  display: flex;
  flex-direction: column;
}

.service_name {
  font-size: 16px;
  line-height: 130%;
  color: var(--transparent-color);
}

@media screen and (min-width: 1024px) {
  .title {
    display: flex;
    flex-direction: column;
  }
}
