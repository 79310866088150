.modal {
  max-width: 815px;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.qrInstruction {
  display: flex;
  flex-direction: column;
  position: relative;
}

.instruction {
  position: relative;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  white-space: pre-line;
  height: 100px;
  overflow-y: hidden;
  &.showMore {
    height: auto;
  }
}

.readMore {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration-line: underline;
  color: var(--main-text);
  text-align: start;
  margin-top: 15px;
  cursor: pointer;
}

.important {
  color: var(--error-color);
  margin-top: 10px;
}

.qrBlock {
  display: flex;
  flex-direction: column;
}

.qrImage {
  width: 144px;
  height: 144px;
  display: none;
}

.btnSave {
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration-line: underline;
  color: var(--link-color);
  margin-top: 10px;
  cursor: pointer;
}

.field_wrapper {
  width: 100%;
  max-width: 100%;
}

.label {
  display: block;
  margin-left: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
}

.copy_field {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
}

.field_bg {
  box-shadow: var(--forms3_shadow);
  background-color: var(--forms_bgc_3);
}

.field_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  font-weight: 300;
  font-size: 16px;
  color: var(--main-text);
}

.copy_icon {
  fill: var(--transparent-color);
}

.copy_prompt {
  position: absolute;
  top: calc(100% - 5px);
  right: 0;
  pointer-events: none;
  padding: 12px 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  z-index: 1;
  background-color: var(--popup_prompt);
}

.prompt_pointer {
  position: absolute;
  bottom: 100%;
  right: 17px;
  border: 10px solid transparent;
  border-bottom-color: var(--popup_prompt);
}

.fieldsRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  max-width: 650px;
}

.timeRow {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  max-width: 650px;
}

.timeBlock {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.timeName {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  margin-right: 10px;
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  height: 55px;
}

.cancel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  color: var(--main-text);
  cursor: pointer;
}

.passInput {
  margin-top: 15px;

  & input {
    height: 50px;
  }
}

@media screen and (min-width: 768px) {
  .modalHeader,
  .btnBlock {
    box-shadow: none;
  }
  .instruction {
    padding-right: 15px;
    height: auto;
  }

  .readMore {
    display: none;
  }

  .timeBlock {
    flex-direction: row;
    margin-top: 0;
  }

  .field_text {
    max-width: 250px;
  }

  .btnSave {
    text-align: center;
  }

  .qrBlock {
    align-items: center;
  }

  .qrInstruction {
    flex-direction: row;
  }

  .passInput {
    margin-top: 0;
  }

  .field_wrapper {
    max-width: 310px;
    margin-right: 30px;
  }

  .fieldsRow {
    flex-direction: row;
  }

  .title {
    font-size: 20;
  }

  .saveBtn {
    width: 240px;
  }

  .qrImage {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .timeRow {
    flex-direction: row;
    align-items: center;
  }
  .timeBlock {
    flex-basis: 50%;
    &:first-child {
      margin-right: 30px;
    }
  }
  .qrImage {
    width: 140px;
    height: 140px;
  }
  .btnSave {
    font-size: 16px;
  }
}

@media screen and (min-width: 1550px) {
  .field_text {
    font-size: 18px;
  }
}
