.select {
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: var(--forms_shadow);
  border-radius: 3px;
  background: var(--elements-bgc);
  flex-basis: 100%;
  width: 100%;
  margin-top: 10px;
  &.selected {
    .checkIcon {
      svg {
        path {
          stroke: url(#gradient);
        }
      }
    }

    .title {
      color: var(--main-text);
    }
  }

  &:hover {
    .title {
      color: var(--main-text);
    }
  }
}

.checkIcon {
  padding: 12px 20px 12px 12px;
  border-right: 1px solid var(--border-color);
  &:active {
    transform: scale(0.97);
  }
}

.title {
  display: flex;
  padding: 12px 18px 12px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 102.9%;
  color: var(--transparent-color);
}

.infoBtn {
  display: none;
  position: relative;
  margin-left: auto;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  top: 110%;
  right: 0;
  width: 300px;
  z-index: 111;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .infoBtn {
    display: flex;
  }
  .select {
    flex-basis: 49%;
  }
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 18px;
  }
}
