.item {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  padding: 76px 15px 26px 15px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
  display: flex;
  align-items: center;
}

.value {
  font-size: 15px;
  word-break: break-all;
  overflow: hidden;
  &.active {
    color: var(--accent-color-light);
  }

  &_edit {
    margin: 0 -10px;
  }
}

.dailyCharge {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--main-text);
  }

  a {
    margin-left: 10px;

    div {
      content: url('../../../../images/vds_question.svg');
    }

    &:hover {
      div {
        content: url('../../../../images/vds_question_hover.svg');
      }
    }
  }
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.dots_wrapper {
  position: relative;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dots_btn {
  display: flex;
  transform: scale(1);
  transition: transform 150ms;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
}

.dropdown {
  position: absolute;
  right: -10px;
  width: fit-content;
  top: calc(100% + 10px);
  border-radius: 3px;
  z-index: 1;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.pointer_wrapper {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -20px;
  right: 6px;
  overflow: hidden;
}

.pointer {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.tool_item {
  &:first-child .tool_btn {
    padding-top: 12px;
  }
  &:last-child .tool_btn {
    padding-bottom: 12px;
  }
}

.tool_btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    background-color: var(--select_option_bgc);
    svg {
      fill: url('#gradient');
    }
  }

  &:disabled {
    color: var(--transparent-color);
    pointer-events: none;
    svg {
      fill: var(--disabled_icon_color);
    }
  }
}

.tools_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}

.tool_icon {
  margin-right: 12px;
  width: 20px;
  fill: var(--icon-color_2);
}

.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}
