.container {
  width: 1600px;
  padding: 0 25px;
  margin: 0 auto;
}

@media (min-width: 768px) and (max-width: 1919px) {
  .container {
    padding: 0 7%;
  }
}
@media (max-width: 1919px) {
  .container {
    width: 100%;
  }
}
