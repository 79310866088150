.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  max-width: 100%;
  color: var(--main-text);
}

.headerTitleBlock {
  display: flex;
  flex-direction: column;

  .vhostName {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--transparent-color);
  }
}

.statusBlock {
  padding: 15px 10px 5px 20px;
  display: flex;
  flex-direction: column;
}

.statusItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  margin-right: 10px;

  color: var(--main-text);

  span:first-child {
    font-weight: 600;
  }

  span:last-child {
    margin-left: 0;
  }

  .status {
    color: rgb(247, 150, 73);

    &.active {
      color: var(--success-color);
    }

    &.disabled {
      color: var(--error-color);
    }
  }
}

.fieldsBlock {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.selectAndBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.input {
  max-width: 100%;
  margin-top: 20px;
  margin-right: 0px;
  padding-bottom: 0;
}

.select {
  max-width: 100%;
  margin-top: 20px;
  margin-right: 0px;

  button {
    height: 47px;
  }

  &.dateSelect {
    width: 195px;
    margin-right: 20px;
  }
}

.searchBtn {
  height: 50px;
  max-width: 254px;
  font-size: 18px;
  text-transform: uppercase;
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  color: var(--main-text);
  cursor: pointer;
}

.timeSelectBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.formFieldsBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.orderDetail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  margin: 20px 0;
}

@media screen and (min-width: 768px) {
  .statusBlock {
    flex-direction: row;
    padding: 15px 25px 5px 35px;
  }

  .headerTitleBlock {
    align-items: center;
    flex-direction: row;

    .vhostName {
      margin-left: 5px;
    }
  }

  .statusItem {
    flex-direction: row;
    align-items: center;

    span:last-child {
      margin-left: 5px;
    }
  }

  .input {
    max-width: 47%;
    margin-top: 20px;
    margin-right: 15px;
  }

  .select {
    max-width: 47%;
    margin-top: 20px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 220px;
      width: 220px;
      margin-right: 10px;
    }
  }

  .searchBtn {
    height: 55px;
    max-width: 254px;
    font-size: 18px;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1024px) {
  .input {
    max-width: 47%;
    margin-top: 20px;
    margin-right: 15px;
  }

  .select {
    max-width: 47%;
    margin-top: 20px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 175px;
      width: 175px;
    }
  }
}

@media screen and (min-width: 1550px) {
  .input {
    max-width: 47%;
    margin-top: 13px;
    margin-right: 15px;
  }

  .select {
    max-width: 47%;
    margin-top: 13px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 185px;
      width: 185px;
    }
  }

  .calendarModal {
    right: 50%;
    transform: translateX(50%);
  }

  .calendar_pointer {
    right: 50%;
  }
}
