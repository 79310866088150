%accent-text-gradient {
  background-image: var(--accent-text-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

%btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--btn-bgc);
  cursor: pointer;
  transition: transform 0.1s;

  &:hover {
    background-image: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );

    .btn_text {
      color: #fff;
      -webkit-text-fill-color: initial;
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

%btn-text {
  @extend %accent-text-gradient;
  font-weight: 800;
  line-height: 1;
  background-image: linear-gradient(
    90.13deg,
    #818bec 0.11%,
    #8457d5 30.86%,
    #8546ce 53.85%,
    #b346ce 99.89%
  );
}

%backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s;
  pointer-events: none;
  opacity: 0;
}

%input {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  padding-left: 15px;
  padding-right: 10px;
  border-radius: 3px;
  border: 1px solid var(--input-border-icon);
  z-index: 1;
  font-size: 16px;
  background-color: var(--elements-bgc);
  color: inherit;

  &.error {
    border: 1px solid var(--error-color);
  }

  &.pr {
    padding-right: 45px;
  }

  &.pl {
    padding-left: 36px;
  }

  &::placeholder {
    font-size: 16px;
    color: var(--auth-placeholder-color);
  }

  &:focus {
    outline: transparent;
    border: none;

    & ~ .input_border {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 4px;
      background-image: var(--accent-gradient);
    }

    & ~ .field_icon {
      fill: var(--main-text);
    }
  }
}

%input_type_number {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }
}

%whois_style {
  background: var(forms_bgc_4);
  box-shadow: var(--forms_shadow);
  margin: 30px 15px;

  max-height: 65vh;
  overflow-y: auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
    width: 4px;
    height: 4px;
  }
}
