.no_results_wrapper {
  text-align: center;
  margin: 40px auto;
  max-width: 500px;
}
.no_results_text {
  font-size: 20px;
  color: var(--transparent-color);
}

.no_service_wrapper {
  max-width: 531px;
  margin: 60px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 40px;
  }
}

.ftp_img {
  display: block;
  margin: 0 auto 20px;
  filter: grayscale(1);
}

.no_service_title {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: var(--main-text);
  margin-bottom: 25px;
}

.no_service_description {
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  color: var(--main-text);
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  gap: 25px;
  margin-top: 25px;
}

.head_row_wrapper {
  display: flex;
  column-gap: 25px;
  align-items: center;
  margin-top: 35px;
}

.head_row {
  width: 100%;
  display: flex;
  gap: 25px;
  padding: 0 15px;
}

.table_head {
  font-size: 14px;
  color: var(--main-text);

  &:nth-child(1) {
    flex-basis: 10%;
  }
  &:nth-child(2) {
    flex-basis: 12%;
    word-break: break-all;
  }
  &:nth-child(3) {
    flex-basis: 12%;
  }
  &:nth-child(4) {
    flex-basis: 15%;
  }
  &:nth-child(5) {
    flex-basis: 15%;
  }
  &:nth-child(6) {
    flex-basis: 10%;
  }
  &:nth-child(7) {
    flex-basis: 15%;
  }
  &:nth-child(8) {
    flex-basis: 22px;
  }
}

@media screen and (min-width: 1500px) {
  .no_results_wrapper {
    max-width: 700px;
  }
}

@media screen and (min-width: 1600px) {
  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
  }
}

.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}
