.domainsZone {
  margin-top: 40px;
}

.domainsZoneTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.chooseAllBlock {
  display: flex;
  align-items: center;

  padding: 30px 10px 10px 15px;

  .chooseAllText {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 113%;
    color: var(--main-text);
    margin-left: 12px;
  }
}

.domainsBlock {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  margin-top: 10px;
  gap: 20px;
}

.domainItemBg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;

  &.selected {
    background: var(--line-gradient);

    .domainName {
      font-weight: 400;
    }

    .domainPrice {
      font-weight: 400;
    }

    .saleEur {
      font-weight: 400;
    }
  }
}

.domainItem {
  padding: 20px 10px 20px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 3px;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_2);
  position: relative;

  &:hover {
    background-color: var(--domains_hover_bgc);
  }
}

.domainName {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  margin-left: 25px;

  &.selected {
    background: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.domainPrice {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);
}

.pricesBlock {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.sale {
  position: absolute;
  right: 11px;
  top: -12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: #ffffff;
  min-width: 56px;
  min-height: 22px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red-text);
  border-radius: 2px;
}

.saleEur {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 70.9%;
  text-decoration-line: line-through;
  color: var(--transparent-color);
  margin-top: 11px;

  .selected & {
    color: var(--red-text);
    text-decoration-color: var(--red-text);
  }
}

.year {
  text-transform: lowercase;
}

.checkbox {
  background-color: var(--forms_bgc_4);
}

.prolongBlock {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
}

.prolongPeriod {
  text-transform: lowercase;
}

@media screen and (min-width: 768px) {
  .domainItemBg {
    // flex-basis: 21%;
    // margin-right: 25px;

    &.transfer {
      // flex-basis: 31%;
    }
  }

  .domainsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 25px;
  }
}
