@import '../../../scss/placeholders.scss';

.form_wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  margin: auto 0;
}

.form {
  width: 261px;
  background: var(--elements-bgc);
  padding: 18px;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
}

.field_wrapper {
  margin-bottom: 10px;
  position: relative;
}

.form_title {
  font-size: 20px;
  margin: 20px auto;
  color: var(--main-text);
}
.field_icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  z-index: 2;
  fill: var(--input-border-icon);
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 5px;
}

.input_wrapper {
  width: 100%;
  height: 50px;
  position: relative;
}

.icon_eye {
  fill: var(--input-border-icon);
}

.input {
  @extend %input;
}

.error_message {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}

.reset_description {
  font-size: 14px;
  line-height: 1.2;
}

.mail_link {
  color: var(--link-color);
  text-decoration: underline;
}

.reset__input_title {
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 5px;
}

.pass_show_btn {
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  &.shown {
    display: flex;
  }
}

.submit_btn {
  @extend %btn;
  height: 50px;
  margin: 10px 0 15px 0;
  width: 100%;
}

.btn_text {
  @extend %btn-text;
  font-size: 18px;
}

.reset_pass_link {
  display: block;
  text-align: center;
  color: var(--link-color);
  font-size: 16px;
  text-decoration: underline;
}

.credentials_error {
  height: fit-content;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--error-color);
  background-color: rgba(255, 68, 102, 0.1);
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .form {
    width: 437px;
    padding: 36px 64px;
  }

  .current_auth_link {
    font-size: 20px;
  }

  .auth_link {
    font-size: 20px;
  }

  .input {
    padding-left: 36px;
  }

  .captcha {
    transform: scale(1);
    margin-bottom: 0;
  }

  .submit_btn {
    height: 55px;
  }

  .field_wrapper {
    margin-bottom: 15px;
  }

  .btn_text {
    font-size: 20px;
  }
}
