.icon {
  fill: var(--icon-color);
  margin-right: 18px;
  // cursor: pointer;
  flex: none;

  &.active {
    fill: url(#gradient);
  }
}

.profile_icon {
  fill: var(--icon-color);
  margin-right: 18px;
  flex: none;
}

.arrow_icon {
  margin-left: auto;
  flex: none;

  stroke: var(--main-text);
  transition: transform 0.5s;

  &.closed {
    transform: rotateZ(-180deg);
  }
}

.list_items_wrapper {
  &:hover {
    .icon {
      fill: url(#gradient);
    }
    .arrow_icon {
      stroke: url(#gradient);
    }
  }
}
