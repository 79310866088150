.modal {
  background: var(--main-bgc);
}

.page_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: var(--main-text);
}
.order_id {
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
}

.focused_input {
  input {
    cursor: pointer;
  }
}

.status_wrapper {
  display: flex;
  margin-bottom: 20px;
}

.creation_date_wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.expiration_date_wrapper {
  display: flex;
  flex-direction: column;
}

//copy field
.field_wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.copy_field {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  box-shadow: var(--forms_shadow);
  background-color: var(--forms_bgc_1);
  cursor: pointer;

  &:hover {
    & .copy_icon {
      fill: url(#gradient);
    }
  }
}

.copy_icon {
  fill: var(--inactive_icon_color);
  position: absolute;
  right: 18px;
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 10px;
}

.field_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  // color: var(--transparent-color);
  color: var(--main-text);
}

.copy_prompt {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 214px;
  height: 41px;
  border-radius: 25px;
  color: #fff;
  background: rgba(63, 63, 63, 0.43);
}

.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
}

.main_title,
.additional_title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: var(--main-text);
}

.select {
  margin-bottom: 12px;
}

.input_field_wrapper {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.total_amount {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.price {
  font-weight: 600;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.order_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  margin-bottom: 20px;
  color: var(--main-text);
}

.buy_btn {
  width: 230px;
  height: 55px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.cancel_btn {
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration: underline;
  color: var(--main-text);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 594px;
  }

  .page_title {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .creation_date_wrapper {
    flex-direction: row;
  }
  .expiration_date_wrapper {
    flex-direction: row;
  }

  .field_wrapper {
    width: 230px;
  }

  .value {
    display: block;
    margin-left: 5px;
  }

  .main_block {
    display: flex;
    justify-content: space-between;
  }

  .input_field_wrapper,
  .select {
    width: 250px;
    padding-bottom: 0px;
  }

  .additional_block {
    display: flex;
    justify-content: space-between;
  }

  .cancel_btn {
    margin-left: 30px;
  }
}
