.domainsZone {
  margin-top: 40px;
}

.domainsZoneTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.domainItem {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 19px 25px;
  align-items: center;
  border-radius: 3px;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_2);
  position: relative;
  flex-basis: 100%;
  margin-top: 20px;

  cursor: pointer;

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    background: var(--icon-color);
    left: 0;
    top: 0;
    box-shadow: var(--line-shadow);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:hover::before,
  &.selected::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, #50d394 0%, #45a884 100%);
    left: 0;
    top: 0;
    box-shadow: var(--line-shadow);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.selected {
    .domainName {
      font-weight: 400;
    }
    .domainPrice {
      font-weight: 400;
    }
  }

  &.notAvailable {
    pointer-events: none;
  }
}

.domainItemTransfer {
  padding: 19px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_2);
  position: relative;
  flex-basis: 100%;
  margin-top: 10px;

  &.selected {
    .domainName {
      font-weight: 400;
    }
    .domainPrice {
      font-weight: 400;
    }
  }
}

.domainsBlock {
  display: flex;
  max-width: 740px;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);

  &.suggested {
    border-bottom: none;
  }
}

.domainsBlockTransfer {
  display: flex;
  max-width: 920px;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);

  &.suggested {
    border-bottom: none;
  }
}

.domainName {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  flex-basis: 70%;

  margin-right: 20px;

  &.notAvailable {
    color: var(--transparent-color);
  }
}

.domainNameTransfer {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  flex-basis: 100%;

  margin-left: 40px;
  margin-right: 20px;
}

.domainPrice {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);
  margin-right: 10px;
}

.domainPriceMobile {
  margin-top: 10px;
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: var(--main-text);
  }
}

.pricesBlockTransfer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-left: 40px;
  margin-top: 10px;
  flex-basis: 100%;

  .saleEur {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin-top: 0px;
  }

  .domainPrice {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }
}

.sale {
  position: absolute;
  right: 11px;
  top: -12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: #ffffff;
  min-width: 56px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e57862;
  border-radius: 2px;
}

.saleEur {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 70.9%;
  text-decoration-line: line-through;
  color: var(--transparent-color);
  margin-top: 11px;
}

.saleEurMobile {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 70.9%;
  text-decoration-line: line-through;
  color: var(--transparent-color);
  margin-top: 11px;
}

.year {
  text-transform: lowercase;
}

.selectBtn {
  grid-column: 4/4;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100.9%;
  text-decoration-line: underline;
  color: var(--main-text);
  flex-basis: 33%;
  text-align: right;

  &.selected {
    background: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-decoration-line: underline;
  }

  &.notAvailable {
    text-decoration-line: none;
    color: var(--error-color);
  }
}

.selectedDomainsBlock {
  margin-top: 22px;
}

.sum {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: var(--main-text);

  display: flex;
  align-items: center;

  span {
    background: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 10px;
  }
}

.selectedDomains {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.selectedItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 550px;
  width: 100%;

  margin-top: 17px;

  div {
    font-size: 16px;
  }

  .cross {
    fill: var(--main-text);
    transform: scale(0.85);
    cursor: pointer;
  }
}

.btnBlock {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-left: 20px;
  color: var(--main-text);

  &:active {
    transform: scale(0.95);
  }

  cursor: pointer;
}

.searchBtn {
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
}

.domainavailability {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  flex-basis: 100%;

  margin-right: 20px;
  margin-left: 40px;
  margin-top: 10px;

  &.available {
    color: var(--success-color);
  }

  &.notAvailable {
    color: var(--error-color);
  }
}

.checkbox {
  min-width: 30px;
  position: absolute;
  top: 25px;
  left: 15px;
}

.prolongBlock {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-basis: 25%;
  font-size: 14px;
}

.prolongPeriod {
  text-transform: lowercase;
}
.transferProlongBlock {
  margin-left: 10px;
}

.domainNameValue {
  word-break: break-word;
}

@media screen and (max-width: 767px) {
  .domainItem {
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .domainPriceMobile,
  .saleEurMobile {
    margin-top: 5px;
  }
  .domainName {
    margin: 0;
    flex-basis: 65%;
    word-break: break-word;
    margin-right: 10px;
  }
  .selectBtn {
    grid-column: 2/2;
    flex-basis: 35%;
  }
  .prolongBlock {
    grid-column: 1/3;
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 100%;
    margin-top: 15px;
  }
  .transferProlongBlock {
    margin-left: 40px;
  }
}

@media screen and (max-width: 449px) {
  .btnBlock {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (min-width: 768px) {
  .pricesBlockTransfer {
    margin-top: 0;
  }

  .domainItem {
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 10px;
  }

  .domainName {
    grid-column: 1/3;
  }

  .pricesBlock,
  .prolongBlock {
    grid-row: 2/2;
  }

  .pricesBlock {
    grid-column: 4/4;
    justify-self: end;
  }

  .checkbox {
    position: relative;
    top: 0;
    left: 0;
  }

  .domainItemTransfer {
    padding: 19px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    box-shadow: var(--forms2_shadow);
    background: var(--forms_bgc_2);
    position: relative;
    flex-basis: 100%;
    margin-top: 10px;

    &.selected {
      .domainName {
        font-weight: 400;
      }
      .domainPrice {
        font-weight: 400;
        margin-right: 20px;
      }
    }
  }

  .domainNameTransfer {
    flex-basis: 33%;
    margin-left: 20px;
  }

  .domainavailability {
    flex-basis: 33%;
    margin-left: 0px;
    margin-top: 0px;
  }

  .pricesBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
  }

  .pricesBlockTransfer {
    margin-left: 0px;
    flex-basis: 33%;
    .saleEur {
      white-space: nowrap;
      margin-top: 0;
      margin-right: 5px;
    }

    .domainPrice {
      white-space: nowrap;
      margin-top: 0;
      margin-right: 5px;
    }
  }

  .domainName {
    flex-basis: 34%;
  }

  .domainPriceMobile {
    display: none;
  }

  .saleEurMobile {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .pricesBlockTransfer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    flex-basis: 33%;
  }
}

@media screen and (min-width: 1550px) {
  .pricesBlockTransfer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 33%;
  }
}
