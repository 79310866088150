.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;

  span {
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.placeholder {
  color: var(--transparent-color);
}

.text {
  width: calc(100% - 20px);
}

.edit_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 39px;
  padding: 10px;
  text-align: left;
  cursor: text;
  &:hover {
    svg {
      fill: var(--accent-color-light);
    }
  }

  &.shadow {
    &:hover {
      box-shadow: 0px 1px 10px rgba(61, 56, 70, 0.15);
      border-radius: 3px;
    }
  }
}

.editBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1px;
  border: 1px solid var(--accent-color-light);
  border-radius: 5px;
  height: 39px;
  max-height: 39px;
  width: 100%;
  gap: 5px;

  input {
    position: relative;
    flex-grow: 1;
    padding: 10px 30px 10px 10px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    background: transparent;
    color: var(--main-text);
    overflow-x: auto;
    z-index: 1;
  }
}

.btn {
  display: none;
  position: absolute;
  right: 10px;

  &_show {
    display: block;
  }

  &__check {
    cursor: pointer;
    z-index: 2;
  }
}

.edit_icon {
  fill: var(--accent-color-light);
}
