@import '../../../scss/placeholders.scss';

.backdrop {
  @extend %backdrop;

  &.shown {
    pointer-events: initial;
    opacity: 1;

    .modalWindow {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.modalWindow {
  position: relative;
  width: 260px;
  height: fit-content;
  padding: 18px;
  border-radius: 3px;
  background-color: var(--elements-bgc);
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.15s, transform 0.15s;
}

.title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.closeBtn {
  display: flex;
  position: absolute;
  right: 18px;
  top: 18px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.icon {
  fill: var(--main-text);
}

.text {
  margin-bottom: 20px;
  font-size: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  margin-left: 8px;
  font-size: 14px;
}

.input_wrapper {
  position: relative;
  width: 100%;
  height: 45px;
}

.input {
  @extend %input;

  &:focus {
    top: 1px;
    left: 1px;
  }
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  margin: 20px auto 0;
  font-size: 18px;
}

.error_msg {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .modalWindow {
    width: 436px;
    padding: 32px 40px;
  }

  .title {
    margin-bottom: 30px;
  }

  .closeBtn {
    top: 30px;
    right: 40px;
  }

  .text {
    margin-bottom: 25px;
  }

  .btn {
    margin: 25px auto 0;
    width: 310px;
    height: 55px;
    font-size: 20px;
  }

  .input_wrapper {
    height: 50px;
  }
}
