@import '../../../scss/placeholders.scss';

.backdrop {
  @extend %backdrop;
  z-index: 200;

  &.shown {
    pointer-events: initial;
    opacity: 1;
  }

  &.main_lt {
    background-color: #fefdff;
    display: flex;
    flex-direction: column;
  }
  &.main_dt {
    background-color: #201a2a;
    display: flex;
    flex-direction: column;
  }
}

.loader__logo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.7s;
}

.loader {
  --delayRange: 0.32s;
  --size: 50px;
  display: flex;
  justify-content: space-between;
  width: calc(var(--size) * 1.2);
  margin: 30px auto 0;
  text-align: center;
}

.loader_circle {
  width: calc(var(--size) / 4);
  height: calc(var(--size) / 4);
  background-color: rgba(132, 87, 213, 1);
  border-radius: 100%;
  display: inline-block;
  animation: three-bounce 1.4s ease-in-out 0s infinite both;

  &.first {
    animation-delay: var(--delayRange);
  }

  &.second {
    animation-delay: calc(var(--delayRange) / 2);
  }
}

@keyframes three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
