@import '../../../../scss/placeholders.scss';

.title {
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  margin-bottom: 20px;
}

.btn_save {
  display: block;
  max-width: 250px;
  height: 50px;
  font-size: 18px;
}

.btn_cancel {
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: 18px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--main-text);
  }
}

.names_block {
  overflow: hidden;
  transition: height 150ms;
  font-size: 14px;
  color: var(--transparent-color);
  @extend %scrollbar;

  .name_item:not(:first-child) {
    opacity: 0;
  }

  &.opened {
    overflow: auto;

    .name_item {
      opacity: 1;
    }
  }
}

.name_item {
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 3px;
}

.btn_more {
  color: var(--link-color);
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid var(--link-color);
  cursor: pointer;
}
