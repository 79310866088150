.bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &.selected {
    box-shadow: var(--forms2_pink_shadow);
    background-image: linear-gradient(180deg, #924ee8 0%, #c630bb 100%);
    pointer-events: none;

    .btn {
      cursor: initial;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      font-weight: 600;
    }
  }
}

.btn {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 15px 20px;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms2_shadow);
  cursor: pointer;
  text-align: start;
  transform: scale(1);
  transition: transform 80ms;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 15px;

  &:active {
    transform: scale(0.97);
  }
}

.img {
  &.without {
    width: 17px;
  }
}

.list_item {
  cursor: pointer;
  align-items: flex-start;
  text-align: start;
  padding: 8px 40px 8px 8px;
}
