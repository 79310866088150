@import '../../../scss/placeholders.scss';

.btn {
  @extend %btn;
  padding: 10px 20px;
  border-width: 0;
  width: 100%;
  // font-size: 16px;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.shadow {
  box-shadow: var(--buttons_shadow);

  &:hover {
    box-shadow: var(--buttons_shadow_active);
  }
}

.block {
  width: auto;
}
.small {
  max-width: 220px;
}
.medium {
  max-width: 230px;
}
.large {
  max-width: 310px;
}

.btn_text {
  @extend %btn-text;
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  margin-top: 3px; // pay attention to this. No way
}

.loader {
  --delayRange: 0.32s;
  --size: 15px;
  display: flex;
  justify-content: space-between;
  width: calc(var(--size) * 2);
  text-align: center;
}

.loader_circle {
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  background-color: rgba(132, 87, 213, 1);
  border-radius: 100%;
  display: inline-block;
  animation: three-bounce 1.4s ease-in-out 0s infinite both;

  &.first {
    animation-delay: var(--delayRange);
  }

  &.second {
    animation-delay: calc(var(--delayRange) / 2);
  }
}

@keyframes three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

// @media screen and (min-width: 768px) {
//   .btn_text {
//     font-size: 16px;
//   }
// }

// @media screen and (min-width: 1281px) {
//   .btn_text {
//     font-size: 20px;
//   }
// }
