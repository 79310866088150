.section_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  margin-bottom: 25px;
}

.subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 113%;
  color: var(--main-text);
  margin-bottom: 15px;
}

.subtitle_wrapper {
  position: relative;
  height: 80px;
  overflow: hidden;
  transition: height 0.15s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    background-image: linear-gradient(
      0deg,
      var(--main-bgc) 30%,
      rgba(0, 0, 0, 0.001) 100%
    );
  }
}

.show_more_btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 115%;
  text-decoration-line: underline;
  color: var(--main-text);
  margin-bottom: 30px;
  cursor: pointer;
}

.add_btn.btn {
  margin-bottom: 40px;
  padding: 19px 0;
  display: none;
  height: 55px;

  & span {
    font-weight: 800;
    font-size: 18px;
  }

  &.shown {
    display: flex;
  }
}

.users_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  margin-bottom: 25px;
  color: var(--main-text);
}

.table_header {
  display: grid;
  grid-template-columns: 177px 127px 103px 50px 30px;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.user_email_lg,
.user_name_lg,
.user_access_lg,
.user_status_lg {
  font-size: 14px;
}

.table_wrapper {
  display: none;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .subtitle {
    font-weight: 300;
    line-height: 130%;
    margin-bottom: unset;
  }
  .show_more_btn {
    display: none;
  }

  .add_btn.btn {
    width: 255px;
    margin-top: 25px;
  }

  .table_wrapper {
    display: block;
  }
  .subtitle_wrapper::after {
    content: none;
  }

  .subtitle_wrapper {
    height: unset;
  }

  .users_title {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .table_header {
    grid-template-columns: 170px 157px 103px 140px 30px;
    justify-content: space-between;
  }
  .section_title {
    margin-bottom: 30px;
  }

  .user_status_lg {
    padding-left: 0px;
  }
  .add_btn.btn {
    width: 255px;
  }
}

@media screen and (min-width: 1550px) {
  .section_title {
    font-size: 22px;
  }
  .add_btn.btn {
    margin-bottom: 50px;
    width: 255px;
  }
  .subtitle {
    font-size: 18px;
    line-height: 130.5%;
  }
  .table_header {
    grid-template-columns: 20% 20% 10% 12% 3%;
    gap: 50px;
    padding-right: 55px;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1900px) {
  .table_header {
    gap: 120px;
  }
}
