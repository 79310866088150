@import '../../../scss/placeholders.scss';

.backdrop {
  @extend %backdrop;

  &.opened {
    pointer-events: initial;
    opacity: 1;
  }
}

.modal_window {
  width: fit-content;
  max-width: 85vw;
  height: fit-content;
  max-height: 90vh;
  padding: 20px 0;
  border-radius: 5px;
  background-color: var(--forms_bgc_1);
  box-shadow: var(--forms2_shadow);
  opacity: 0;
  transform: scale(0);
  transition: opacity 150ms, transform 150ms;

  &.opened {
    opacity: 1;
    transform: scale(1);
  }
}

.heading_wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  margin-bottom: 15px;
  padding: 0 18px;
}

.heading {
  font-weight: 600;
  font-size: 17px;
}

.date {
  white-space: nowrap;
}

.icon_cross {
  width: 19px;
  height: 19px;
  flex: none;
  fill: var(--main-text);
  cursor: pointer;
}

.blur {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    left: 0;
    height: 30px;
    background-image: linear-gradient(0deg, var(--forms_bgc_1) 20%, rgba(0, 0, 0, 0.001));
  }
}

.list {
  padding: 10px 18px 30px;
  overflow-y: scroll;
  height: fit-content;
  max-height: 70vh;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }
}

.list_item {
  display: grid;
  grid-template-columns: 80px 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  height: fit-content;
  padding: 15px 20px;
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.total {
  font-size: 16px;
  padding: 0 18px 0 30px;
}

.total_label {
  font-weight: 600;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
}

@media screen and (min-width: 768px) {
  .modal_window {
    width: 80vw;
    padding: 30px 0;
  }

  .heading_wrapper {
    padding: 0 30px;
    margin-bottom: 35px;
  }

  .heading {
    font-size: 18px;
  }

  .total {
    padding: 0 50px 0 30px;
    display: flex;
    justify-content: flex-end;
  }

  .total_label {
    margin-right: 5px;
  }

  .table_head_row {
    display: flex;
    gap: 10px;
    padding: 0 60px;
    margin-bottom: 8px;
  }

  .table_head {
    font-size: 14px;
    &:first-child {
      flex-basis: 40%;
    }
    &:nth-child(2) {
      flex-basis: 15%;
    }
    &:nth-child(3) {
      flex-basis: 25%;
    }
    &:nth-child(4) {
      flex-basis: 20%;
    }
  }

  .list {
    padding: 10px 30px 30px;
    max-height: 60vh;
  }

  .list_item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 30px;
    height: 55px;
  }

  .row_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      flex-basis: 40%;
    }
    &:nth-child(2) {
      flex-basis: 15%;
    }
    &:nth-child(3) {
      flex-basis: 25%;
    }
    &:nth-child(4) {
      flex-basis: 20%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .modal_window {
    width: 900px;
    padding: 40px 0;
  }

  .heading_wrapper {
    padding: 0 40px;
  }

  .heading {
    font-size: 20px;
  }

  .table_head_row {
    padding: 0 100px;
    margin-bottom: 15px;
  }

  .total {
    padding: 0 70px 0 40px;
  }

  .list {
    padding: 10px 40px 30px;
  }

  .list_item {
    padding: 0 60px;
  }
}
