@import '../../../scss/placeholders.scss';

.modal {
  width: calc(100% - 60px);
}

.form {
  display: grid;
  gap: 15px;
}

.title_wrapper {
  color: #b3a9c1;
}

.form_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.field_bg {
  background-color: var(--forms_bgc_3) !important;
  box-shadow: var(--forms2_shadow) !important;
}

.phoneInputButton {
  background-color: transparent !important;
}

.required_star {
  color: #d93f21;
  font-size: 20px;
}

.submit_btn.btn {
  width: 100%;
  padding-bottom: 22px;
  padding-top: 22px;
  display: none;
  &.shown {
    display: block;
  }
}
// ADAPTIVE

@media screen and (min-width: 768px) {
  .modal {
    width: 390px;
  }

  .submit_btn.btn {
    padding-bottom: 21px;
    padding-top: 21px;
  }
}
