.bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &.selected {
    box-shadow: var(--forms2_pink_shadow);
    background-image: linear-gradient(180deg, #924ee8 0%, #c630bb 100%);

    .btn {
      width: calc(100% - 2px);
      height: calc(100% - 2px);

      > p {
        font-weight: 600;
      }
    }
  }
}

.btn {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 15px 50px 15px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms2_shadow);
  cursor: pointer;

  > p {
    text-align: start;
    font-size: 16px;
  }
}

.img {
  &.grey {
    filter: grayscale(1);
  }
}

.list_item {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 15px 20px;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: var(--select_option_bgc);

    .img {
      filter: grayscale(0);
    }
  }

  &:first-of-type {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  &:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
  max-height: 260px;
  border-radius: 3px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    min-height: 35px;
    background-color: rgba(132, 87, 213, 1);
  }
}
