.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.input {
  max-width: 100%;
  padding-bottom: 0;
}

.field {
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  height: 55px;
}

.cancel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-top: 22px;
  color: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.nsInputBlock {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.not_exist {
  margin-top: 25px;
  min-height: 50px;
  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
    padding: 15px;
    background-color: var(--elements-bgc3);
    box-shadow: var(--forms2_shadow);
    color: var(--red-text);
  }
}

.infoBtn {
  position: absolute;
  margin-left: auto;
  top: 30px;
  right: 0px;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  bottom: 100%;
  right: 0;
  width: 232px;
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .saveBtn {
    font-size: 20px;
  }

  .saveBtn {
    width: 254px;
  }

  .cancel {
    margin-top: 0px;
    margin-left: 20px;
  }
}
