.item {
  height: 62px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 25px;
  position: relative;
  user-select: text;

  &.active_server > .value:first-child {
    color: var(--accent-color-light);
  }
}

.item_wrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 25px;
  align-items: center;
}

.value {
  font-size: 15px;
  text-align: start;

  &:nth-child(1) {
    flex-basis: 10%;
  }
  &:nth-child(2) {
    flex-basis: 12%;
    word-break: break-all;
  }
  &:nth-child(3) {
    flex-basis: 12%;
  }
  &:nth-child(4) {
    flex-basis: 15%;
  }
  &:nth-child(5) {
    flex-basis: 15%;
  }
  &:nth-child(6) {
    flex-basis: 10%;
  }
  &:nth-child(7) {
    flex-basis: 15%;
  }
  &:nth-child(8) {
    flex-basis: 22px;
  }
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}

.dots_wrapper {
  position: relative;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dots_btn {
  display: flex;
  transform: scale(1);
  transition: transform 150ms;
  cursor: pointer;

  &:hover svg {
    fill: url('#gradient');
  }

  &:active {
    transform: scale(0.9);
  }
}

.dropdown {
  position: absolute;
  right: -10px;
  width: fit-content;
  top: calc(100% + 10px);

  border-radius: 3px;
  z-index: 1;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.pointer_wrapper {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -20px;
  right: 6px;
  overflow: hidden;
}

.pointer {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.tool_item {
  &:first-child .tool_btn {
    padding-top: 12px;
  }
  &:last-child .tool_btn {
    padding-bottom: 12px;
  }
}

.tool_btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 20px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    background-color: var(--select_option_bgc);
    svg {
      fill: url('#gradient');
    }
  }

  &:disabled {
    color: var(--transparent-color);
    pointer-events: none;
    svg {
      fill: var(--disabled_icon_color);
    }
  }
}

.tool_icon {
  margin-right: 12px;
  width: 20px;
  fill: var(--icon-color_2);
}
