.select_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: var(--header-btn-width);
  // height: var(--header-btn-height);
  // border-radius: 99px;
  // background-image: var(--accent-gradient);

  &.burger_type {
    --header-btn-width: 66px;
    --header-btn-height: 36px;
  }

  &.auth_type {
    --header-btn-width: 66px;
    --header-btn-height: 33px;
  }

  &.main_type {
    --header-btn-width: 62px;
    --header-btn-height: 33px;
  }

  &:hover {
    .dropdown_list {
      transform: translateY(0);
    }

    .lang_dropdown {
      pointer-events: initial;
    }
  }
}

.current_lang {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--header-btn-width));
  height: calc(var(--header-btn-height));
  border-radius: 99px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-text);

  line-height: 1;

  &:hover {
    color: var(--accent-color-light);
  }

  img {
    margin-right: 7px;
  }
}

.icon {
  stroke: var(--main-text);
  transform: rotateZ(180deg);
  stroke-width: 2;
  height: 8px;
  width: 12px;
  margin-left: 5px;
}

.lang_dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 150px);
  padding: 15px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  z-index: 101;
}

.dropdown_list {
  position: relative;
  max-width: calc(100% + 50px);
  height: fit-content;
  padding: 10px 0;
  border-radius: 8px;
  background-color: var(--forms_bgc_4);
  box-shadow: 0px 1px 8px rgba(65, 38, 114, 0.15);
  transform: translateY(calc(-200% - 20px));
  transition: transform 0.15s;
}

.shevron_wrapper {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.dropdown_shevron {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: 3px 2px 12px rgba(57, 41, 85, 0.25);
}

.lang_item {
  width: 100%;
  height: 25px;
}

.lang_btn {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 15px;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;

  img {
    margin-right: 7px;
  }

  &:hover {
    color: var(--accent-color-light);
  }
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .select_wrapper {
    &.burger_type {
      --header-btn-width: 72px;
      --header-btn-height: 39px;
    }

    &.auth_type {
      --header-btn-width: 75px;
      --header-btn-height: 40px;
    }
  }

  .current_lang {
    font-size: 18px;
  }
}
