.list {
  position: relative;
  display: none;

  margin-top: 18px;

  &::before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    margin-left: 39px;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: var(--icon-color);
  }

  &.closed {
    display: block;
  }
}
.user_name,
.list_name {
  font-size: 18px;
  line-height: 110.4%;
  color: var(--main-text);
}

.user_name {
  margin-top: 11px;
}

.email {
  font-size: 14px;
  line-height: 70.9%;
  margin-top: 10px;
  margin-bottom: 11px;
  color: #716d79;
  &.email_lt {
    color: #b3a9c1;
  }
}

.list_name {
  padding-bottom: 20px;
  padding-top: 20px;
}

.list_item {
  margin-left: 70px;
}

.list_item_container {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 26px;
  padding-right: 26px;

  &.opened {
    background-color: var(--nearby-elements-bgc);
    box-shadow: var(--burger-menu-elem-box-shadow);

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      background: var(--accent-gradient);
    }
  }
}

.open_list_icon {
  margin-left: auto;
  margin-right: 26px;
  transform: rotateZ(-90deg);

  &.closed {
    transform: rotateZ(90deg);
  }
}

.list_item_link {
  font-size: 16px;
  color: var(--main-text);
  display: flex;
  align-items: center;
  height: 31px;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .user_name,
  .list_name {
    font-size: 20px;
  }

  .list_item_link {
    font-size: 18px;
    height: 43px;
  }

  .list_item_container {
    padding-left: 48px;
    padding-right: 60px;
  }
}
