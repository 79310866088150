.item {
  height: 62px;
  background: var(--forms_bgc_3);
  box-shadow: var(--forms_shadow);
  border-radius: 5px;
  height: fit-content;
}

.item_btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  gap: 30px;
  position: relative;
}
.date_wrapper {
  display: flex;
  flex-direction: column;
}

.time {
  color: var(--transparent-color);
  font-size: 14px;
}

.value {
  font-size: 16px;
  text-align: start;
  word-break: break-word;

  &.empty {
    color: var(--transparent-color);
  }

  &:nth-child(1) {
    flex-basis: 13%;
  }
  &:nth-child(2) {
    flex-basis: 42%;
    word-wrap: break-word;
  }
  &:nth-child(3) {
    flex-basis: 30%;
    word-wrap: break-word;
  }
  &:nth-child(4) {
    flex-basis: 15%;
  }
}
