@import '../../../../scss/placeholders.scss';

.modal {
  max-width: 400px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
}

.name {
  font-size: 16px;
  margin-bottom: 12px;
}

.description {
  font-size: 13px;
  margin-bottom: 16px;
}

.inputs_block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 290px;
}

.input {
  background-color: var(--forms_bgc_3);
}

.btn_save {
  display: block;
  margin: 0 auto;
  max-width: 250px;
  height: 50px;
  font-size: 18px;
}

.btn_cancel {
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: 18px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--main-text);
  }
}

.names_wrapper {
  margin-bottom: 10px;
}

.names_block {
  overflow: hidden;
  transition: height 150ms;
  font-size: 14px;
  color: var(--transparent-color);
  @extend %scrollbar;

  .name_item:not(:first-child) {
    opacity: 0;
  }

  &.opened {
    overflow: auto;

    .name_item {
      opacity: 1;
    }
  }
}

.name_item {
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 3px;
}

.btn_more {
  color: var(--link-color);
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid var(--link-color);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .description {
    margin-bottom: 20px;
    text-align: center;
  }
  .inputs_block {
    max-width: initial;
  }

  .title {
    text-align: center;
    margin-bottom: 5px;
  }

  .name {
    text-align: center;
    margin-bottom: 20px;
  }

  .names_wrapper {
    text-align: center;
  }
}
