.modal {
  background: var(--main-bgc);
  max-width: 380px;
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.page_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: var(--main-text);
}

.first_row,
.second_row {
  display: flex;
  justify-content: space-between;
}

.first_row {
  margin-bottom: 10px;
}
.second_row {
  margin-bottom: 20px;
}

.label {
  width: 70%;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
}

.value {
  width: 30%;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
}

.value {
  &.green {
    color: var(--success-color);
  }
  &.red {
    color: var(--red-color);
  }
}

.tarif_name {
  font-size: 14px;
  line-height: 130%;
  color: var(--transparent-color);
}

.select {
  margin-bottom: 12px;
}

.inputClassName {
  background-color: var(--nearby-elements-bgc);
}

.input_field_wrapper {
  margin-bottom: 12px;
}

.input {
  height: 50px;
}

.buy_btn {
  width: 230px;
  height: 55px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
}

.cancel_btn {
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration: underline;
  color: var(--main-text);
  cursor: pointer;
}

.warning_text {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.hidden_area {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
  margin-top: 8px;
}

.item {
  color: var(--transparent-color);
  font-size: 14px;
}
.services_names_wrapper {
  display: inline;
  transition: max-height 1s;

  &.active {
    display: block;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(161, 161, 161, 23%);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(132, 87, 213, 1);
    }

    max-height: 75px;
    overflow-x: hidden;
  }
}

.suspendpenaltywarn {
  color: var(--red-text);
  font-size: 14px;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .page_title {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .input_field_wrapper,
  .select {
    min-width: 254px;
    padding-bottom: 0px;
  }

  .buy_btn {
    width: 254px;
  }
}
