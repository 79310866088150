.modal {
  background: var(--main-bgc);
}

.main_block {
  padding-bottom: 15px;
}

.page_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: var(--main-text);
}
.ip_id {
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
}

.select {
  margin-bottom: 12px;
}

.input_field_wrapper {
  margin-bottom: 12px;
}

.input {
  height: 50px;
}

.buy_btn {
  width: 230px;
  height: 55px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
}

.cancel_btn {
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration: underline;
  color: var(--main-text);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 355px;
  }

  .parameters_block {
    padding: 25px 51px 32px 51px;
  }

  .header_block {
    margin-bottom: 16px;
  }

  .page_title {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .value {
    display: block;
    margin-left: 5px;
  }

  .input_field_wrapper,
  .select {
    min-width: 254px;
    max-width: 254px;
    padding-bottom: 0px;
  }

  .buy_btn {
    width: 254px;
  }
}
