.table {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  align-items: stretch;
  column-gap: 20px;
  row-gap: 15px;
}

.tableHeader {
  display: none;
  padding: 20px 23px;
  width: 100%;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--menu-text-3);
  align-items: center;
  margin-right: 20px;
  &.first_item {
    flex-basis: 8.5%;
  }
  &.second_item {
    flex-basis: 14.9%;
  }
  &.third_item {
    flex-basis: 12%;
  }
  &.fourth_item {
    flex-basis: 9.2%;
  }
  &.fifth_item {
    flex-basis: 18.3%;
  }
  &.sixth_item {
    flex-basis: 9.9%;
  }
  &.seventh_item {
    flex-basis: 11.3%;
  }
  &.eighth_item {
    flex-basis: 15.9%;
  }
}

.item {
  flex-direction: column;
  padding: 15px 17px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  margin-bottom: 7px;
  position: relative;
  // cursor: pointer;
  position: relative;
  height: 100%;

  // &.desktop {
  //   &:hover::before,
  //   &.selected::before {
  //     content: '';
  //     width: 3px;
  //     height: 100%;
  //     position: absolute;
  //     background: linear-gradient(
  //   90.13deg,
  //   #818bec 0.11%,
  //   #8457d5 30.86%,
  //   #8546ce 53.85%,
  //   #b346ce 99.89%
  // );
  //     left: 0;
  //     top: 0;
  //     box-shadow: 0px 1px 8px rgb(65 38 114 / 15%);
  //     border-top-left-radius: 3px;
  //     border-bottom-left-radius: 3px;
  //   }
  // }
}

.dots {
  position: absolute;
  right: 25px;
  top: 21px;
  cursor: pointer;
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourth,
.tableBlockFifth,
.tableBlockSixth,
.tableBlockSeventh,
.tableBlockEighth {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.list {
  position: absolute;
  width: 210px;
  background-color: var(--forms_bgc_4);
  top: calc(50% + 20px);
  right: -25px;
  box-shadow: var(--forms2_shadow);
  border-radius: 5px;
  display: none;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--forms_bgc_4);
    transform: rotate(45deg);
    top: -7px;
    right: 27px;
    box-shadow: -1px -1px 3px -2px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &.opened {
    display: block;
  }
}

.settings_btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 20px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--main-text);

  svg {
    width: 16px;
  }

  svg,
  path {
    fill: var(--icon-color_2);
  }

  &:hover {
    background-color: var(--select_option_bgc);

    svg,
    path {
      fill: url(#gradient);
    }
  }

  &:disabled {
    color: var(--transparent-color);
    pointer-events: none;
    svg {
      path {
        fill: var(--disabled_icon_color);
      }
    }
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // padding-top: 12px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // padding-bottom: 12px;
  }
}

.setting_text {
  margin-left: 18px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
}

.line {
  border-bottom: 1px solid var(--select_option_bgc);
  margin-top: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.mobileBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btnsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileBtn {
  flex-basis: 32%;
  padding: 10px 0;
  background: var(--forms_bgc_4);

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  color: var(--main-text);
  box-shadow: var(--forms2_shadow);

  svg {
    width: 16px;
    margin-bottom: 5px;
  }

  svg,
  path {
    fill: var(--transparent-color);
  }

  &:hover {
    svg,
    path {
      fill: url(#gradient);
    }
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.check_box {
  margin-right: 20px;
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}

.inactive {
  color: var(--transparent-color);
}

@media screen and (min-width: 600px) {
  .item {
    flex-basis: calc(50% - 15px);
    margin-right: 15px;
  }

  .item_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1080px) {
  .item {
    flex-basis: calc(33% - 15px);
    margin-right: 15px;
  }
}

@media screen and (min-width: 1359px) {
  .item {
    flex-basis: calc(25% - 15px);
  }
}

@media screen and (min-width: 1600px) {
  .item_container {
    display: flex;
    align-items: center;
  }

  .header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    margin-right: 0;
  }
  .dots {
    top: 25px;
  }

  .table {
    display: block;
  }

  .tableHeader {
    display: flex;
    padding: 20px 26px;
  }

  .item {
    flex-direction: row;
    align-items: center;
    padding: 20px 15px;
    flex-basis: auto;
  }

  .tableBlockFirst,
  .tableBlockSecond,
  .tableBlockThird,
  .tableBlockFourth,
  .tableBlockFifth,
  .tableBlockSixth,
  .tableBlockSeventh,
  .tableBlockEighth {
    margin-top: 0px;
    margin-right: 20px;
  }

  .tableBlockFirst {
    flex-basis: 8.5%;
  }

  .tableBlockSecond {
    flex-basis: 14.9%;
  }

  .tableBlockThird {
    flex-basis: 12%;
  }

  .tableBlockFourth {
    flex-basis: 9.2%;
  }

  .tableBlockFifth {
    flex-basis: 18.3%;
  }

  .tableBlockSixth {
    flex-basis: 9.9%;
  }

  .tableBlockSeventh {
    flex-basis: 11.3%;
  }

  .tableBlockEighth {
    flex-basis: 15.9%;
  }

  .tableHeader {
    padding: 22px 15px;
  }

  // .item {
  //   padding: 22px 27px;
  // }

  .item_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1918px) {
  .tableHeader {
    padding: 22px 27px;
  }

  .item_text {
    font-size: 16px;
  }
}
