.control_btn {
  position: absolute;
  display: flex;
  align-items: center;
  right: 21px;
  top: 6px;
  height: 30px;
  cursor: pointer;
}

.dot {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: var(--main-text);
  }
}

.list {
  position: absolute;
  width: 210px;
  background-color: var(--forms_bgc_4);
  top: 40px;
  right: -21px;
  box-shadow: var(--forms2_shadow);
  border-radius: 5px;
  display: none;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--forms_bgc_4);
    transform: rotate(45deg);
    top: -7px;
    right: 27px;
    box-shadow: -1px -1px 3px -2px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &.opened {
    display: block;
  }
}

.access_rights_btn,
.settings_btn,
.remove_btn {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--select_option_bgc);

    & .icon {
      fill: url(#gradient);
    }
  }

  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
}

.has_access {
  display: none;

  &.full {
    display: block;
  }
}

.setting_text,
.delete_text,
.access_text {
  font-weight: 300;
  font-size: 18px;
  line-height: 70.9%;
}

.remove_btn,
.access_rights_btn,
.settings_btn {
  &.owner {
    opacity: 0.3;

    pointer-events: none;
  }
}

.icon {
  fill: var(--icon-color);
}

.control_btn_lg {
  position: absolute;
  display: flex;
  align-items: center;
  height: 30px;
  top: -15px;

  & .list {
    top: 40px;
  }

  cursor: pointer;
}

.user_email_lg {
  padding-left: 27px;
}

// ADAPTIVE

@media screen and (min-width: 768px) {
  .list {
    width: 193px;
  }
}
