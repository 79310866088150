@import '../../../scss/placeholders.scss';

.backdrop {
  @extend %backdrop;
  background-color: rgba(0, 0, 0, 0.6);

  &.opened {
    pointer-events: initial;
    opacity: 1;
  }
}
