.react-calendar__tile.react-calendar__month-view__days__day {
  &:hover {
    border-radius: 15px;
    background-color: rgba(179, 70, 206, 0.1);
    cursor: pointer;
  }
}

.calendar__arrow {
  &:hover {
    fill: var(--accent-color-light);
  }

  &.left {
    transform: rotateZ(180deg);
  }
}

.react-calendar__navigation__prev-button {
  margin-left: 20px;
}

.react-calendar__navigation__next-button {
  margin-right: 20px;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
}

.calendar_block {
  position: relative;
  width: 284px;
  padding: 5px 15px 15px;
  border-radius: 5px;
  background: var(--nearby-elements-bgc);
  box-shadow: var(--forms2_shadow);
}

.calendar_pointer-wrapper {
  position: absolute;
  top: 100%;
  right: 50%;
  width: 25px;
  height: 15px;
  transform: translateX(50%);
  overflow: hidden;
}

.calendar_pointer {
  margin: -4px 0 10px 10px;
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg);
  background-color: var(--nearby-elements-bgc);
  box-shadow: var(--forms2_shadow);
}

.react-calendar__month-view__weekdays__weekday {
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--transparent-color);
  text-align: center;

  abbr[title] {
    text-decoration: none;
  }
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);
}
.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 7px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--main-text);
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--transparent-color);
}

.react-calendar__tile--range {
  background-color: rgba(179, 70, 206, 0.1);
}

.react-calendar__tile--range.react-calendar__tile--rangeStart,
.react-calendar__tile--range.react-calendar__tile--rangeEnd {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );
  }
  abbr {
    position: absolute;
    color: #ffffff;
  }
}

.react-calendar__tile--range.react-calendar__tile--rangeStart {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.react-calendar__tile--range.react-calendar__tile--rangeEnd {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.react-calendar__navigation__arrow {
  cursor: pointer;
  padding: 0 4px;
  fill: var(--main-text);
}
