.submit_btn {
  height: 55px;
  max-width: 100%;
  width: 100%;
  text-transform: uppercase;
}

.btnsBlock {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-align: center;
  text-decoration-line: underline;
  color: var(--main-text);
}

.form {
  width: 100%;
  margin-top: 25px;
}

@media screen and (min-width: 768px) {
  .btnsBlock {
    margin: 20px 0px;
    display: block;
  }
  .submit_btn {
    max-width: 255px;
    width: 255px;
  }
  .link {
    margin-left: 25px;
    margin-top: 0px;
  }
  .fieldsBlock {
    margin: 0 0px;
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1550px) {
  .fieldsBlock {
    margin: 0 100px;
  }
  .btnsBlock {
    margin: 20px 100px;
  }
}

@media screen and (min-width: 1918px) {
}
