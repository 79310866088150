.modal {
  background: var(--main-bgc);
  width: 380px;
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  max-width: 100%;
  color: var(--main-text);
}

.statusBlock {
  margin: 15px 0 0 0;
}

.fieldsBlock {
  margin-top: 20px;
}

.statusItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: var(--main-text);
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .status {
    color: rgb(247, 150, 73);

    &.active {
      color: var(--success-color);
    }

    &.disabled {
      color: var(--error-color);
    }
  }
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-left: 0px;
  color: var(--main-text);
  cursor: pointer;
}

.searchBtn {
  height: 55px;
  max-width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}

.namesBlock {
  padding: 10px 30px 0px;
}

.warning_text {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.hidden_area {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
  margin-top: 8px;
}

.item {
  color: var(--transparent-color);
  font-size: 14px;
}

.services_names_wrapper {
  display: inline;
  transition: max-height 1s;

  &.active {
    display: block;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(161, 161, 161, 23%);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(132, 87, 213, 1);
    }

    max-height: 75px;
    overflow-x: hidden;
  }
}
