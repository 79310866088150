.table_head_row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 10px;
}

.table_head {
  width: 100px;
  font-weight: 400;
  font-size: 14px;
}

.table_row {
  width: 100%;
  height: 50px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.table_btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: start;
  border-radius: 3px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms2_shadow);
  transition: transform 0.15s;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
}

.table_date {
  width: 100px;
  font-weight: 400;
  font-size: 16px;
  color: var(--link-text);
}

.table_amount {
  width: 100px;
  font-weight: 400;
  font-size: 16px;
}

.table_footer {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.income_sum {
  width: 100px;
  font-weight: 600;
  font-size: 16px;
}

@media screen and (min-width: 767px) {
  .table_head_row {
    justify-content: space-between;
    padding: 0 80px 10px;
  }

  .table_btn,
  .table_footer {
    justify-content: space-between;
    padding: 0 80px;
  }

  .table_head,
  .table_date,
  .table_amount,
  .income_sum {
    width: 120px;
  }
}

@media screen and (min-width: 1550px) {
  .table_date,
  .table_amount,
  .income_sum {
    font-size: 18px;
  }
}
