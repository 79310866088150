@import '../../../scss/placeholders.scss';

.filterBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
}

.newTicketBtn {
  height: 50px;
  text-transform: uppercase;
  max-width: 230px;
  max-height: 53px;
}

.searchInput {
  min-width: 233px;
  margin-right: 0px;
  margin-top: 15px;
}

.formBlock {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.archiveBtn {
  margin-left: 20px;
}

.archivePopUp {
  white-space: nowrap;
}

.filterBtnBlock {
  position: relative;
}

.bg {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 140;
}

.calendarBlock {
  margin-top: 17px;
  position: relative;
}

.filterModal {
  position: absolute;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_1);
  border-radius: 3px;
  z-index: 141;
  margin-top: 20px;
  width: 90%;
}

.calendarModal {
  position: absolute;
  display: none;
  right: -10px;
  top: calc(100% + 15px);

  &.opened {
    display: block;
  }
}

.calendar_pointer {
  right: 34px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 261px;
}

.inputRow {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.selectAndBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.select {
  margin-top: 15px;
  max-width: 100%;
  margin-right: 0px;
}

.dateSelect {
  max-width: 216px;
  margin-right: 15px;
}

.btnBlock {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--modal_footer_shadow);
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-left: 0px;
  margin-top: 20px;
  color: var(--main-text);
  cursor: pointer;
}

.timeSelectBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.searchBtn {
  height: 50px;
  max-width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}

.formHeader {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--modal_header_shadow);
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 113%;
    color: var(--main-text);
  }
}

.crossIcon {
  fill: var(--main-text);
}

.input_bgc {
  background-color: var(--forms_bgc_3);
}

.calendarBtn {
  &.filtered {
    svg {
      fill: url(#gradient);
    }
  }
}

@media screen and (max-width: 767px) {
  .filterBlock {
    flex-direction: column-reverse;
  }
  .newTicketBtn {
    margin-bottom: 30px;
    max-height: 53px;
    max-width: 100%;
  }
  .formContainer {
    padding: 0 14px;
    height: 100%;
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .formContainer {
    padding: 0;
    margin-bottom: 10px;
    display: flex;
  }

  .formHeader {
    display: none;
  }

  .searchBtn {
    max-width: 230px;
  }
  .clearFilters {
    margin-left: 40px;
    margin-top: 0px;
  }
  .btnBlock {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    background: none;
    box-shadow: none;
    margin-top: 20px;
  }
  .filterModal {
    padding: 20px;
    margin-top: 0;
    top: calc(120% + 10px);
    left: 0px;
    &::after {
      content: '';
      position: absolute;
      left: 15px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--forms_bgc_2);
      clear: both;
    }
  }
  .form {
    min-width: 645px;
    // flex-direction: row;
    flex-wrap: wrap;
  }
  .searchInput {
    min-width: 285px;
    margin-right: 15px;
    margin-top: 15px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .timeSelectBlock {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 12px;
    width: 94%;
  }
  .inputRow {
    flex-direction: column;
    max-width: 47%;
  }
  .selectAndBtn {
    max-width: 47%;
    flex-direction: column;
  }
  .select {
    max-width: 287px;
    margin-right: 0px;
    margin-top: 15px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .dateSelect {
    margin-right: 15px;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .formContainer {
    display: block;
  }

  .select {
    max-width: 287px;
    margin-right: 0px;
    margin-top: 10px;
    &:first-child {
      margin-top: 10px;
    }
  }
  .form {
    min-width: 768px;
    flex-direction: column;
  }
  .searchInput {
    min-width: 232px;
    margin-right: 10px;
    margin-top: 0px;
  }
  .timeSelectBlock {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .inputRow {
    max-width: 100%;
    flex-direction: row;
  }
  .selectAndBtn {
    max-width: 100%;
    flex-direction: row;
  }
  .select {
    max-width: 246px;
    margin-right: 10px;
  }
  .dateSelect {
    max-width: 190px;
    width: 190px;
  }
}

@media screen and (min-width: 1550px) {
  .calendarModal {
    right: 50%;
    transform: translateX(50%);
  }

  .calendar_pointer {
    right: 50%;
  }
}

@media screen and (min-width: 1918px) {
  .filterModal {
    padding: 26px 31px;
  }
  .form {
    min-width: 950px;
  }
  .searchInput {
    min-width: 280px;
    margin-right: 22px;
  }
  .select {
    max-width: 295px;
    margin-right: 22px;
  }
  .dateSelect {
    max-width: 225px;
    width: 225px;
  }
}
