@import '../../../scss/placeholders.scss';

.form_wrapper {
  height: fit-content;
  width: fit-content;
  padding: 30px 0px;
  margin: auto 0;
}

.auth_links_wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 30px;
  text-transform: uppercase;
}

.input_field_wrapper {
  margin-bottom: 10px;
}

.current_auth_link {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 99px;
    background-image: linear-gradient(
      90.13deg,
      #818bec 0.11%,
      #8457d5 30.86%,
      #8546ce 53.85%,
      #b346ce 99.89%
    );
    box-shadow: 0px 1px 6px rgba(113, 49, 193, 0.55);
  }
}

.auth_link {
  font-size: 16px;
  color: var(--main-text);
  text-transform: uppercase;
}

.form {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: fit-content;
  padding: 18px;
  margin-bottom: 25px;
  border-radius: 3px;
  background: var(--elements-bgc);
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
}

.submit_btn {
  height: 50px;
  margin: 10px 0 15px 0;
  font-size: 18px;
}

.reset_pass_link {
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
  color: var(--link-color);
  border-bottom: 1px solid var(--link-color);
}

.social_title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
}

.social_list {
  display: flex;
  width: 146px;
  margin: 0 auto;
  justify-content: space-between;
}

.captcha {
  transform: scale(0.74);
  transform-origin: 0 0;
  margin-bottom: -15px;
  min-height: 61px;
}

.error_message {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}

.credentials_error {
  height: fit-content;
  padding: 5px 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  color: var(--error-color);
  background-color: rgba(255, 68, 102, 0.1);
}

.changed_pass {
  @extend .credentials_error;
  background-color: var(--success-bgc);
  color: var(--success-color);
}

.loaderBlock {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.loader {
  position: absolute;
  --delayRange: 0.32s;
  --size: 50px;
  display: flex;
  justify-content: space-between;
  width: calc(var(--size) * 1.2);
  margin: 80px auto 0;
  text-align: center;
}

.loader_circle {
  width: calc(var(--size) / 4);
  height: calc(var(--size) / 4);
  background-color: #8546ce;
  border-radius: 100%;
  display: inline-block;
  animation: three-bounce 1.4s ease-in-out 0s infinite both;

  &.first {
    animation-delay: var(--delayRange);
  }

  &.second {
    animation-delay: calc(var(--delayRange) / 2);
  }
}

@keyframes three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .form {
    width: 313px;
    padding: 25px 40px;
  }

  .captcha {
    transform: scale(0.77);
  }
}

@media screen and (min-width: 1281px) {
  .form {
    width: 431px;
    padding: 36px 64px;
  }

  .input_field_wrapper {
    margin-bottom: 20px;
  }

  .auth_links_wrapper {
    height: 40px;
  }

  .current_auth_link {
    font-size: 20px;
  }

  .auth_link {
    font-size: 20px;
  }

  .captcha {
    transform: scale(1);
    margin-bottom: 0;
    min-height: 83px;
  }

  .submit_btn {
    margin: 20px 0;
    height: 55px;
    font-size: 20px;
  }

  .credentials_error {
    font-size: 15px;
  }

  .social_title {
    font-size: 18px;
  }
}
