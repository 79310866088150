.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  gap: 25px;
  margin: 25px 0;
}

.head_row_wrapper {
  display: flex;
  column-gap: 25px;
  align-items: center;
  margin-top: 35px;
}

.head_row {
  width: calc(100% - 55px);
  display: flex;
  gap: 10px;
  padding: 0 15px;
}

.table_head {
  font-size: 14px;

  &:nth-child(1) {
    flex-basis: 10%;
    flex-shrink: 0;
  }
  &:nth-child(2) {
    flex-basis: 7%;
  }
  &:nth-child(3) {
    flex-basis: 10%;
    flex-shrink: 0;
  }
  &:nth-child(4) {
    flex-basis: 10%;
  }
  &:nth-child(5) {
    flex-basis: 10%;
  }
  &:nth-child(6) {
    flex-basis: 8%;
  }
  &:nth-child(7) {
    flex-basis: 8%;
  }
  &:nth-child(8) {
    flex-basis: 13%;
  }
  &:nth-child(9) {
    flex-basis: 8%;
  }
  &:nth-child(10) {
    flex-basis: 12%;
  }
}

.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}

.tenth_element {
  width: 22px;
}

@media screen and (min-width: 1600px) {
  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
  }
}
