.server_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  background: var(--forms_bgc_3);
  box-shadow: var(--forms_shadow);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tarif_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: var(--forms_bgc_3);

  img {
    width: 31px;
    height: 37px;
  }

  position: relative;
}

.server_info {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.additional_info_item {
  display: none;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  background: var(--forms_bgc_3);
  // padding: 19px 25px 19px 0px;

  &.opened {
    display: flex;
  }
}

.service_name {
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
  margin: 0px 25px 0px 25px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.priceList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.control_bts_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.domainName {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--main-text);
}

.domainProtect {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--transparent-color);
}

.costBlock {
  display: flex;
  flex-direction: column;
}

.cost {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--main-text);
}

.discountBlock {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  align-self: start;

  .discountPerCent {
    color: var(--error-color);
    margin-right: 5px;
  }

  .fullcost {
    text-decoration: line-through;
    color: var(--transparent-color);
  }
}

.deleteIcon {
  position: absolute;
  top: 17px;
  right: 17px;
  margin-left: 40px;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.shevron {
  stroke: var(--icon-color);
  margin-right: 15px;
  transition: transform 0.2s ease;
  align-self: center;
  transform: rotateX(180deg);

  &:hover {
    stroke: var(--transparent-color);
  }

  &.opened {
    transform: rotateX(360deg);
  }
}

.shevron_container {
  border-top: 1px solid var(--inactive_icon_color);
  align-self: stretch;
  cursor: pointer;
  height: 50px;
  order: 3;
}

.countItem {
  font-weight: 400;
  font-size: 18px;
  color: var(--main-text);
  margin-left: 10px;
}
.btn_delete {
  // position: absolute;
  // top: 15px;
  // right: 15px;
  cursor: pointer;
}

.periodInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--transparent-color);
    text-align: start;

    &:first-child {
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: 768px) {
  .periodInfo {
    flex-direction: row;
  }

  .countItem {
    position: relative;
    right: 0px;
  }
  .btn_delete {
    position: static;
    margin-left: 40px;
  }

  .tarif_info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .additional_info_item {
    padding-left: 30px;
  }

  .service_name {
    margin: unset;
  }

  .shevron_container {
    order: unset;
    border-top: unset;
    align-self: center;
  }

  .server_item {
    padding: 19px 25px 19px 25px;
  }

  .server_info,
  .cost {
    margin-bottom: 0;
  }

  .priceList {
    display: flex;
    // margin-left: 20px;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 0;

    // & :not(:last-child) {
    //   margin-right: 10px;
    // }
    // & :nth-child(1) {
    //   flex-basis: 20%;
    // }
    // & :nth-child(2) {
    //   flex-basis: 30%;
    // }
    // & :nth-child(3) {
    //   flex-basis: 30%;
    // }
  }

  .costBlock {
    display: flex;
    // align-items: flex-end;
    margin-top: 0;
    margin-left: 15px;
  }

  .deleteIcon {
    position: relative;
    top: 0;
    right: 0;
  }
}
