.item {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  padding: 76px 15px 26px 15px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  user-select: text;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
}

.value {
  font-size: 15px;
  word-break: break-all;
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.dots_wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
}

.dots_btn {
  transform: scale(1);
  transition: transform 150ms;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
}

.dropdown {
  position: absolute;
  right: -10px;
  width: 240px;
  top: calc(100% + 10px);
  padding: 30px 10px 20px 20px;
  border-radius: 3px;
  z-index: 1;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.pointer_wrapper {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -20px;
  right: 6px;
  overflow: hidden;
}

.pointer {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

.tool_item:not(:last-child) {
  margin-bottom: 15px;
}

.tool_btn {
  display: flex;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    font-weight: 400;
    svg {
      fill: url('#gradient');
    }
  }

  &:disabled {
    color: var(--transparent-color);
    pointer-events: none;
    svg {
      fill: var(--disabled_icon_color);
    }
  }
}

.tool_icon {
  margin-right: 12px;
  width: 20px;
}

.tools_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}
.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}
