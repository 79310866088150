@import '../../../scss/placeholders.scss';

.icon_btn {
  background-color: var(--btn-bgc);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  box-shadow: var(--forms_shadow);
  border-radius: 3px;
  cursor: pointer;
  transition: transform 100ms;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.93);
  }

  &:hover:not(:disabled) {
    svg {
      rect {
        stroke: url(#gradient);
      }
      path {
        fill: url(#gradient);
      }
    }
  }
}

@media screen and (min-width: 1281px) {
  .icon_btn {
    width: 50px;
    height: 50px;
  }
}
