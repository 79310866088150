.modal {
  max-width: 900px;
  background-color: var(--main-bgc);
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 20px;
}

.error_message {
  margin-left: -10px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}

.error_department {
  margin-left: 0px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.submit_btn {
  height: 55px;
  max-width: 100%;
  width: 100%;
  text-transform: uppercase;
}

.departmentSelect {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.select {
  margin-top: 20px;
  z-index: 9;

  div {
    font-size: 18px;
  }
}

.input_field_wrapper {
  margin-top: 20px;
  input {
    font-size: 18px;
    &::placeholder {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 768px) {
  .departmentSelect {
    flex-direction: row;
  }

  .submit_btn {
    max-width: 255px;
    width: 255px;
  }
}
