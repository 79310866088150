.selectDropdownClass {
  z-index: 9 !important;
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.formBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
}

.formBlockTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--main-text);
}

.formFieldsBlock {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 14px;
  column-gap: 20px;
}

.input {
  max-width: 100%;
  // margin-top: 13px;
}

.phoneInputLabel {
  margin: 0 0 5px 10px;
}

.select {
  max-width: 100%;
  // margin-top: 13px;

  span {
    font-size: 16px;
  }

  div {
    font-size: 16px;
  }
}

.phoneInput {
  width: 100%;
  max-width: 100%;
  margin: 13px 0 0 0;

  input {
    height: 46px !important;
    background-color: var(--elements-bgc) !important;
  }

  div {
    background-color: var(--elements-bgc) !important;
  }
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  height: 55px;
}

.cancel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  color: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.offerBlock {
  display: flex;
  align-items: center;
  // margin-top: 13px;
  // max-width: 270px;
}

.checkbox {
  margin-right: 10px;
}

.offerBlockText {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--main-text);
}

.offerBlockLink {
  color: var(--link-color);
  // text-decoration: underline;
  cursor: pointer;
  text-align: start;
  border-bottom: 1px solid var(--link-color);
}

.nsInputBlock {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.infoBtn {
  position: absolute;
  margin-left: auto;
  top: 28px;
  right: 0px;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  bottom: 100%;
  right: 0;
  width: 232px;
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

.inputAddressWrapp {
  padding-right: 35px;
}

@media screen and (min-width: 768px) {
  .saveBtn {
    font-size: 20px;
  }

  .formBlock {
    margin-top: 25px;
  }

  .phoneInput {
    max-width: 48%;
    margin: 13px 20px 0 0;
  }

  .input {
    &.inputAddress {
      max-width: 100%;
    }
  }

  .saveBtn {
    width: 254px;
  }

  .formFieldsBlock {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1281px) {
  .infoBtn {
    top: 31px;
  }
}
