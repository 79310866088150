.nav_bar {
  user-select: none;
  position: relative;
  display: flex;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -30px;
    width: 100vw;
    height: 3px;
    z-index: 0;
    background-color: var(--elements-bgc);
    box-shadow: var(--line-shadow);
  }
}

.scroll_area {
  display: flex;
  height: 32px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 30px;
  padding-left: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  font-size: 16px;
  white-space: nowrap;
  color: var(--main-text);

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    color: var(--accent-color-light);
  }
}

.active_link {
  position: relative;
  cursor: initial;
  pointer-events: none;

  &:hover {
    color: inherit;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 99px;
    z-index: 1;
    background-image: var(--accent-gradient);
  }
}

.blur {
  width: 117%;
  left: -30px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background: linear-gradient(90deg, var(--main-bgc) 30%, rgba(0, 0, 0, 0.001) 100%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0px;
    width: 35px;
    height: 30px;
    background: linear-gradient(270deg, var(--main-bgc) 30%, rgba(0, 0, 0, 0.001) 100%);
  }
}

@media screen and (min-width: 768px) {
  .nav_bar {
    &::after {
      right: -55px;
    }
  }

  .blur {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  .link {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
}

@media screen and (min-width: 1550px) {
  .nav_bar {
    &::after {
      right: -130px;
    }
  }
}

@media screen and (min-width: 1918px) {
  .nav_bar {
    &::after {
      right: -150px;
    }
  }
}
