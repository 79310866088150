.modal {
  max-width: 420px;
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  max-width: 95%;
  color: var(--main-text);
}

.warn {
  font-size: 16px;
}

.deleteInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 17px;
  color: var(--main-text);
}

.statusItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: var(--main-text);
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .status {
    color: rgb(247, 150, 73);

    &.active {
      color: var(--success-color);
    }

    &.disabled {
      color: var(--error-color);
    }
  }
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  color: var(--main-text);
  cursor: pointer;
}

.searchBtn {
  height: 50px;
  max-width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}
