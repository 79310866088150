@import '../../../../scss/placeholders.scss';

.title {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
  color: var(--red-text);
  text-transform: uppercase;
}

.text {
  font-size: 15px;
  margin-bottom: 10px;
}

.warn {
  font-size: 14px;
  margin-bottom: 10px;
}

.cancel_btn {
  display: block;
  max-width: 240px;
  height: 50px;
  font-size: 18px;
}

.delete_btn {
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--main-text);
  }
}

.item {
  margin-bottom: 2px;
}

.names_block {
  overflow: hidden;
  transition: height 150ms;
  font-size: 14px;
  color: var(--transparent-color);
  @extend %scrollbar;

  .name_item:not(:first-child) {
    opacity: 0;
  }

  &.opened {
    overflow: auto;

    .name_item {
      opacity: 1;
    }
  }
}

.name_item {
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 3px;
}

.btn_more {
  color: var(--link-color);
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid var(--link-color);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 18px;
  }

  .warn {
    font-size: 16px;
  }

  .cancel_btn {
    font-size: 20px;
  }

  .delete_btn {
    font-size: 20px;
  }
}
