.burger {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 16, 25, 0.3);
  z-index: 110;
}

.burger_nav {
  display: flex;
  padding: 20px 23px;
  min-height: 70px;
  position: sticky;
  top: 0;
  background-color: var(--elements-bgc);
  box-shadow: var(--modal_header_shadow);
  z-index: 111;
}

.theme_btn_wrapper {
  margin-right: 15px;
}

.list_item {
  &:hover {
    .icon {
      fill: url(#gradient);
    }
    .arrow_icon {
      stroke: url(#gradient);
    }
  }
  // cursor: pointer;
}

.exit_list_item {
  &:hover {
    .icon {
      fill: url(#gradient);
    }
  }
}

.icon {
  fill: var(--icon-color);
  margin-right: 18px;
  cursor: pointer;

  &.active {
    fill: url(#gradient);
  }
}

.balance_wrapper_dt,
.balance_wrapper_lt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 14px;
  background-color: var(--nearby-elements-bgc);
  padding-left: 26px;
  padding-right: 26px;
}

.balance_wrapper_lt {
  box-shadow: 0px 1px 8px rgba(65, 38, 114, 0.15);
  margin: 14px 29px 23px;
  border-radius: 5px;
}
.balance_wrapper_dt {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.21);
}

.balance_text {
  padding-top: 17px;
  padding-bottom: 17px;
  color: var(--main-text);
  font-size: 17px;
  line-height: 70.9%;
}

.balance_sum {
  font-size: 17px;
  line-height: 70.9%;
  text-decoration-line: underline;
  color: var(--link-color);
}

.exit_wrapper {
  display: flex;
  align-items: center;
  padding: 25px 26px 0 26px;
  margin-top: 25px;
  cursor: pointer;
}

.exit_name {
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);
}

.exit_list_item {
  position: relative;
  margin-bottom: 50px;

  &::before {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    left: 26px;
    right: 26px;
    border-radius: 5px;
    background: var(--icon-color);
  }
}

.balance_wrapper_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .exit_wrapper {
    padding: 35px 60px 0 48px;
  }
  .balance_wrapper_dt,
  .balance_wrapper_lt {
    padding-left: 48px;
    padding-right: 60px;
  }

  .balance_text {
    font-size: 20px;
  }

  .balance_sum {
    font-size: 20px;
  }
  .balance_wrapper_lt {
    margin: 14px 0;
    border-radius: unset;
  }

  .exit_list_item {
    &::before {
      left: 48px;
      right: 60px;
    }
  }

  .burger_nav {
    padding-left: 48px;
  }
}
