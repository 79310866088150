.modalBg {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--elements-bgc);
  z-index: 149;
  overflow-y: auto;
  padding-top: 50px;
}

.modalBlock {
  margin: auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 424px;
}

.linksBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 22px;
  margin-top: 20px;
}

.modalTopBlock {
  background: var(--forms_bgc_3);
  box-shadow: var(--forms_shadow);
  border-radius: 3px;
  padding: 63px 22px;
  width: 100%;
  max-width: 424px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.approved {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 130.5%;
  color: var(--success-color);
  margin-top: 35px;
}

.completed {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
  margin-top: 25px;
  text-align: center;
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration-line: underline;
  color: var(--link-color);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modalBg {
    align-items: center;
    background-image: var(--auth-lines);
    background-repeat: no-repeat;
    background-position: 50% 60%;
  }

  .linksBlock {
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .modalTopBlock {
    padding: 38px 50px;
    margin: 0 30px;
  }
}
