@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-light.eot');
  src: url('../fonts/proximanova-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-light.woff2') format('woff2'),
    url('../fonts/proximanova-light.woff') format('woff'),
    url('../fonts/proximanova-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-regular.eot');
  src: url('../fonts/proximanova-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-regular.woff2') format('woff2'),
    url('../fonts/proximanova-regular.woff') format('woff'),
    url('../fonts/proximanova-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-semibold.eot');
  src: url('../fonts/proximanova-semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-semibold.woff2') format('woff2'),
    url('../fonts/proximanova-semibold.woff') format('woff'),
    url('../fonts/proximanova-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-bold.eot');
  src: url('../fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-bold.woff2') format('woff2'),
    url('../fonts/proximanova-bold.woff') format('woff'),
    url('../fonts/proximanova-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-extrabld.eot');
  src: url('../fonts/proximanova-extrabld.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-extrabld.woff2') format('woff2'),
    url('../fonts/proximanova-extrabld.woff') format('woff'),
    url('../fonts/proximanova-extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima';
  src: url('../fonts/proximanova-black.eot');
  src: url('../fonts/proximanova-black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-black.woff2') format('woff2'),
    url('../fonts/proximanova-black.woff') format('woff'),
    url('../fonts/proximanova-black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
