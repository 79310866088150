.btn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.error {
    border: 1px solid var(--error-color);
  }
}

.check {
  display: none;
  stroke: var(--accent-color-light);

  &.active {
    display: flex;
  }
}
