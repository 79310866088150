@import '../../../scss/placeholders.scss';

// lang
.phone_label {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 14px;
  color: var(--main-text);
}

.wrapper {
  // margin-bottom: 14px;
}

.lang_container {
  height: 50px;
  position: relative;
}

.lang_btn {
  [class*='selected-flag'] {
    width: 45px;
    height: 100%;
    padding-left: 15px;
  }
  &.extra_style.opened {
    background-color: var(--nearby-elements-bgc);
    border: none;
    z-index: 8;
    &.opened {
      background-color: var(--nearby-elements-bgc);
    }

    & > div[aria-expanded] {
      background-color: var(--forms_bgc_4);
    }
    &.lightTheme > div[aria-expanded] {
      background-color: var(--btn-bgc);
    }

    &.lightTheme {
      &:hover > div {
        background-color: var(--btn-bgc);
      }
    }

    &:hover > div {
      background-color: var(--forms_bgc_4);
    }
  }
}

.required_star {
  color: #d93f21;
  font-size: 14px;
}

.react_phone_input.lang {
  width: 100%;
  border: none;
  box-shadow: var(--forms_shadow);
  background-color: var(--nearby-elements-bgc);

  @extend %input;
  height: 50px;
  position: static;
  padding-left: 50px;

  &:disabled {
    color: var(--transparent-color);
  }

  @media (min-width: 1280px) {
    height: 50px;
  }
}

.drop_down {
  &.list {
    background-color: var(--nearby-elements-bgc);
  }

  &.list {
    &.lightThemeDrop {
      &.list_hover {
        width: 250px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--accent-gradient);
          border-radius: 5px;
        }

        & li[aria-selected] {
          background-color: var(--btn-bgc);
        }
        & li:hover {
          background-color: var(--btn-bgc);
        }

        & span:last-child {
          color: var(--main-text);
        }
      }
    }

    &.list_hover {
      width: 250px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #3b3447;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--accent-gradient);
        border-radius: 5px;
      }

      & li[aria-selected] {
        background-color: var(--forms_bgc_4);
      }
      & li:hover {
        background-color: var(--forms_bgc_4);
      }

      & span:last-child {
        color: var(--main-text);
      }
    }
  }
}

.error_message {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
}
