.modal {
  background-color: var(--elements-bgc);
  max-width: 410px;
  box-shadow: var(--forms3_shadow);
}

.modal_title {
  font-size: 18px;
  font-weight: 600px;
}

.alert_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

.cancel_btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
  text-decoration: underline;
  cursor: pointer;
}
// ADAPTIVE
@media screen and (min-width: 768px) {
  .alert_text {
    font-size: 18px;
  }
}
