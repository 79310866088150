.logo {
  flex: none;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 102px;
    height: 45px;
  }
}
