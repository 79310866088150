.show_more {
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  margin-top: 10px;
  cursor: pointer;
  color: var(--link-color);
}

.btn {
  &.hidden {
    display: none;
  }
}

.no_messages {
  color: var(--main-text);
}

.clear_btn {
  margin-top: 10px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .clear_btn {
    font-size: 18px;
  }
}
