.giftBlock {
  margin-top: 16px;
}

.titleBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 113%;
  color: var(--main-text);
}

.descr {
  margin-top: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}

.cardBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  .card {
    flex-basis: 32%;

    padding: 8px 23px;

    background: var(--forms_bgc_3);
    box-shadow: var(--forms2_shadow);
    border-radius: 3px;
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cardTitle {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      text-transform: uppercase;

      background: linear-gradient(
        90.13deg,
        #818bec 0.11%,
        #8457d5 30.86%,
        #8546ce 53.85%,
        #b346ce 99.89%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 10px;
    }

    .img {
      margin-top: auto;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #7c66a4;
      text-align: center;
      margin-bottom: 10px;
    }

    .priceBlock {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 70.9%;
        color: var(--main-text);
        text-decoration: line-through;
      }

      span:last-child {
        margin-top: 7px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 70.9%;
        color: #fa6848;
      }
    }
  }
}

@media screen and (max-width: 756px) {
  .cardBlock {
    .card {
      padding: 10px 7px;
      min-height: 145px;
      .cardTitle {
        font-size: 14px;
      }

      .img {
        font-size: 16px;
      }

      .priceBlock {
        span:first-child {
          font-size: 12px;
        }

        span:last-child {
          font-size: 12px;
        }
      }
    }
  }
}
