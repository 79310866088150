.container {
  max-height: 100vh;
  --right-padding: 30px;
  padding: 100px var(--right-padding) 125px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--main-bgc);

  scrollbar-color: rgba(132, 87, 213, 1) rgba(161, 161, 161, 3%);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(132, 87, 213, 1);
  }

  &.scroll_forbidden {
    overflow: hidden;
    padding-right: calc(var(--right-padding) + 5px);
  }
}

.aside_menu_container {
  display: none;
}

@media screen and (min-width: 768px) {
  .container {
    --right-padding: 60px;
    padding: 127px var(--right-padding) 210px 60px;
  }

  .aside_menu_container {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin: 0 auto;
    padding: 127px var(--right-padding) 80px 200px;
    width: 100%;
  }
}

@media screen and (min-width: 1550px) {
  .container {
    --right-padding: 135px;
    padding: 127px var(--right-padding) 188px 259px;
  }
}

@media screen and (min-width: 1918px) {
  .container {
    --right-padding: 155px;
    margin: unset;
    padding: 127px var(--right-padding) 120px 290px;
  }
}
