.notification_message_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close_btn_wrapper {
  position: relative;
}

.close_btn {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 5px;
    content: ' ';
    height: 16px;
    width: 2px;
    background: var(--main-text);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::after,
    &::before {
      background: var(--accent-gradient);
    }
  }
}

.notification_message {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-text);
  margin-right: 15px;
}
