.navigation {
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--elements-bgc);
  box-shadow: var(--box-shadow);
  transition: width 0.25s;
  z-index: 10;
  width: 117px;
  user-select: none;
}

.navigation_pinned {
  width: 84px;
}

.list {
  width: 100%;
}

.logo_container {
  display: flex;
  justify-content: center;
}

.logo {
  margin: 14px 0px 60px 0px;
  transition: margin 0.15s;
}

.pinned_logo {
  width: auto;
  // margin-right: -24px;
  // margin-left: -18px;
  // padding-left: 4px;
  // padding-right: 4px;
  // transform: scale(0.65);
  transition: margin 0.15s;
}

.img {
  fill: var(--icon-color);
  margin-top: 23px;
  cursor: pointer;
}

.item {
  position: relative;
  margin-bottom: 10px;
  border: none;
  background-color: var(--elements-bgc);
}

.text {
  color: var(--main-text);
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 21px;
  margin-top: 10px;
  text-align: center;
  padding-left: 14px;
  padding-right: 14px;

  &_inactive {
    display: none;
  }
}

.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--nearby-elements-bgc);
  box-shadow: var(--elements-shadow);

  .img {
    fill: url(#gradient);
  }

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: var(--accent-gradient);
  }
}

.inactive {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    .img {
      fill: url(#gradient);
    }
  }
}

.pin_wrapper {
  margin-bottom: 84px;
  transition: transform 0.15s;

  &.transformed {
    transform: rotateZ(-90deg);
  }
  &:hover {
    .pin_icon {
      fill: url(#gradient);
    }
  }
}

.pin_icon {
  fill: var(--icon-color);
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .logo {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .logo {
    margin-right: 0;
    // margin-left: 0;
  }
}

@media screen and (min-width: 1550px) {
  .navigation {
    width: 130px;
  }
  .navigation_pinned {
    width: 84px;
  }
}
