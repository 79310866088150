@import '../../../../scss/placeholders.scss';

.filterBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
}

.newTicketBtn {
  height: 50px;
  text-transform: uppercase;
  max-width: 230px;
  max-height: 53px;
}

.inputHeight {
  height: 45px !important;
  background-color: var(--forms_bgc_3);

  input {
    background-color: var(--forms_bgc_3);
  }
}

.inputHeightExpire {
  height: 45px !important;
  background-color: var(--forms_bgc_3);
}
.formBlock {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.archiveBtn {
  margin-left: 20px;
}

.filterBtnBlock {
  position: relative;
}

.bg {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 140;
  overflow-y: auto;
}

.calendarBlock {
  margin-top: 35px;
  position: relative;
}

.filterModal {
  position: fixed;
  max-height: 90vh;
  overflow-y: hidden;
  position: 'relative';
  top: 50%;
  transform: translate(0, -50%);

  // padding: 17px 14px;
  box-shadow: var(--forms2_shadow);
  background: var(--elements-bgc);
  border-radius: 3px;
  margin: 20px;
  z-index: 141;
}

.calendarModal {
  position: absolute;
  display: none;
  right: -10px;
  top: calc(100% + 15px);

  &.expenses {
    right: -500%;
  }

  &.opened {
    display: block;
  }
}

.calendar_pointer {
  right: 34px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 261px;
}

.fieldsBlock {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  padding: 17px 14px;
  max-height: 60vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }
}

.selectAndBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.input {
  max-width: 100%;
  margin-top: 20px;
  margin-right: 0px;
}

.select_wrapper {
  background-color: var(--forms_bgc_3);
}

.select {
  max-width: 100%;
  margin-top: 20px;
  margin-right: 0px;

  button {
    height: 47px;
  }

  &.dateSelect {
    width: 195px;
    margin-right: 20px;
  }
}

.btnBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-top: 17px;
  flex-direction: column;
  justify-content: center;

  padding: 14px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--modal_footer_shadow);
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-left: 0px;
  margin-top: 20px;
  color: var(--main-text);
  cursor: pointer;
}

.timeSelectBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchBtn {
  height: 50px;
  max-width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}

.formHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 17px 14px;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--modal_header_shadow);

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 113%;
    color: var(--main-text);
  }
}

.crossIcon {
  fill: var(--main-text);
}

@media screen and (max-width: 767px) {
  .filterBlock {
    flex-direction: column-reverse;
  }
  .newTicketBtn {
    margin-bottom: 30px;
    max-height: 53px;
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .calendarModal {
    &.expenses {
      right: -10px;
    }
  }

  .bg {
    align-items: center;
  }

  .formHeader {
    display: none;
  }

  .searchBtn {
    max-width: 230px;
  }

  .clearFilters {
    margin-left: 40px;
    margin-top: 0px;
  }

  .btnBlock {
    flex-direction: row;
    justify-content: flex-start;

    padding: unset;
    z-index: unset;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: unset;
    box-shadow: unset;
  }

  .fieldsBlock {
    max-height: 100vh;
    overflow: visible;
    padding: 0 0 17px 0;
  }

  .filterModal {
    position: absolute;
    max-height: unset;
    padding: 20px;
    top: calc(90% + 10px);
    left: -290px;
    overflow-y: visible;
    transform: unset;

    &::after {
      content: '';
      position: absolute;
      left: 285px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--elements-bgc);
      clear: both;
    }
  }

  .form {
    min-width: 645px;

    &.expenses {
      min-width: 600px;
    }
  }

  .input {
    max-width: 280px;
    margin-top: 20px;
    margin-right: 15px;
  }

  .select {
    max-width: 280px;
    margin-top: 20px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 220px;
      width: 220px;
      margin-right: 10px;
    }
  }

  .timeSelectBlock {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1024px) {
  .form {
    min-width: 768px;
    &.expenses {
      min-width: 730px;
    }
  }

  .input {
    max-width: 228px;
    margin-top: 20px;
    margin-right: 15px;
  }

  .select {
    max-width: 228px;
    margin-top: 20px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 175px;
      width: 175px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .form {
    min-width: 1070px;
    &.expenses {
      min-width: 770px;
    }
  }

  .filterModal {
    left: -20px;

    &::after {
      left: 15px;
      top: -10px;
    }
  }

  .input {
    max-width: 240px;
    margin-top: 13px;
    margin-right: 15px;
  }

  .select {
    max-width: 240px;
    margin-top: 13px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 185px;
      width: 185px;
    }
  }

  .calendarModal {
    right: 50%;
    transform: translateX(50%);
  }

  .calendar_pointer {
    right: 50%;
  }
}

@media screen and (min-width: 1918px) {
  .filterModal {
    padding: 26px 31px;
  }

  .form {
    min-width: 1020px;
  }
}
