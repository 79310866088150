.filters_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 400px;
  width: 90vw;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_1);
  border-radius: 3px;
  overflow: hidden;
  z-index: 151;
  opacity: 0;
  pointer-events: none;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 150ms;

  &.opened {
    pointer-events: initial;
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

.formHeader {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 17px;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    top: -17px;
    left: -14px;
    right: -14px;
    bottom: 0;
    z-index: -1;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: var(--modal_header_shadow);
    background-color: var(--forms_bgc_3);
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 113%;
    color: var(--main-text);
  }
}

.btn_block {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding-top: 10px;
  background-color: var(--forms_bgc_1);

  &::after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: -14px;
    right: -14px;
    top: 0;
    z-index: -1;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: var(--modal_footer_shadow);
    background-color: var(--forms_bgc_3);
  }
}

@media screen and (min-width: 768px) {
  .filters_modal {
    position: absolute;
    left: initial;
    right: 0;
    top: calc(100% + 15px);
    overflow: initial;
    height: fit-content;
    max-width: initial;
    width: fit-content;
    padding: 10px 20px 20px;
    transform: scale(0);

    &.opened {
      transform: scale(1);
    }

    &::after {
      content: '';
      position: absolute;
      left: initial;
      right: 13px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--forms_bgc_1);
      // clear: both;
    }
  }

  .btn_block {
    position: static;
    &::after {
      display: none;
    }
  }

  .formHeader {
    position: static;

    &::after {
      display: none;
    }
  }
}

@media screen and (min-width: 1281px) {
  .filters_modal {
    &::after {
      right: 15px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .filters_modal {
    left: -20px;
    right: initial;

    &::after {
      left: 34px;
    }
  }
}

@media screen and (min-width: 1918px) {
  .filters_modal {
    padding: 26px 31px;
  }
}
