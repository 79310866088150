@import '../../../scss/placeholders.scss';

.pr35 {
  padding-right: 35px;
}

.dropdown_wrapper {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 10;
  padding-bottom: 15px;
}

.dropdown {
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
}

.suggestion_btn {
  width: 100%;
  padding: 10px 20px;
  text-align: start;
  cursor: pointer;

  &.active {
    background-color: var(--select_option_bgc);
  }
}

.suggestion_main_text {
  font-weight: 500;
}
