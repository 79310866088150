.modal {
  max-width: 370px;
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  max-width: 100%;
  color: var(--main-text);
}

.headerTitleBlock {
  display: flex;
  flex-direction: column;

  .vhostName {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--transparent-color);
  }
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-left: 0px;
  color: var(--main-text);
  cursor: pointer;
}

.searchBtn {
  height: 50px;
  max-width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}

.radioBlock {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.money_info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
}

.new_plan {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
  margin-top: 20px;
}

.pricInfoBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--main-text);
  }

  span:last-child {
    text-align: start;
  }
}
