.modal {
  max-width: 690px;
  background-color: var(--main-bgc);
}

.modalBg {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 149;
  overflow-y: hidden;
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.formBlock {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-top: 0;
  }
  &.last {
    padding-bottom: 25px;
  }
}

.formBlockTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 113%;
  margin-bottom: 5px;
  color: var(--main-text);
}

.formFieldsBlock {
  display: flex;
  flex-wrap: wrap;

  &.first {
    align-items: center;
  }
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  height: 55px;
}

.select {
  max-width: 100%;
  margin-top: 15px;
}

.inputBig {
  max-width: 100%;
  margin-top: 15px;
  padding-bottom: 0;
}

.error_message {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 3px;
}

.error_message_addpayer {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 10px;
}

.inputAmountBlock {
  display: flex;
  align-items: center;
  margin-top: 15px;
  flex-basis: 100%;
  position: relative;

  .input {
    width: 100%;
  }

  .currency {
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 1;
    font-weight: 400;
    font-size: 17px;
    line-height: 130.5%;
    color: var(--main-text);
  }

  .inputHeight {
    input {
      padding-right: 70px;
    }
  }
}

.paymentMethodBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin: 15px 0px 0 0;
  padding: 14px;
  box-shadow: var(--forms2_shadow);
  background-color: var(--forms_bgc_3);
  position: relative;
  max-height: 50px;
  height: 50px;

  img {
    width: 20px;
    height: 20px;
  }

  &.selected {
    span {
      max-width: 70%;
    }
    .iconCheck {
      display: flex;
    }
  }

  .iconCheck {
    stroke: var(--success-color);
    display: none;
    position: absolute;
    right: 16px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: var(--main-text);
    margin-left: 10px;
    max-width: 280px;
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .balance {
    text-align: left;
    margin-left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: var(--transparent-color);
  }

  &:active {
    transform: scale(0.95);
  }
}

.infotext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
  margin: 17px 0;

  span {
    color: var(--error-color);
  }
}

.addNewPayerBtn {
  margin: 15px 15px;
  display: flex;
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 70.9%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: var(--link-color);
  cursor: pointer;
}

.offerBlock {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.checkbox {
  margin-right: 10px;
}

.offerBlockText {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--main-text);
  text-align: start;
}

.offerBlockLink {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
  text-align: start;
}

.inputPerson {
  margin-top: 13px;
  max-width: 100%;
}

.addPayerBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.nsInputBlock {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.infoBtn {
  position: absolute;
  margin-left: auto;
  top: 30px;
  right: 0px;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  top: 90%;
  right: 0;
  width: 232px;
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

.additionalPayMethodBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.additionalSelectPayMentMethod {
  max-width: 100%;
  width: 100%;
}
.phoneInput {
  margin-top: 15px;
  max-width: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .additionalPayMethodBlock {
    flex-direction: row;
  }

  .paymentMethodBtn {
    max-width: 31%;
    flex-basis: 31%;
    margin-top: 15px;
    &:not(:nth-child(3n + 3)) {
      margin-right: 19px;
    }
  }

  .inputAmountBlock {
    flex-basis: 30%;
  }

  .addNewPayerBtn {
    width: auto;
    margin: 13px 0 5px 0;
  }

  .offerBlock {
    margin-top: 20px;
  }

  .inputPerson {
    max-width: 30%;
    margin-right: 20px;
  }

  .select {
    max-width: 48%;
    margin-right: 20px;

    &.additionalSelectPayMentMethod {
      max-width: 48%;
    }

    &:nth-child(2n) {
      margin-right: 0px;
    }
  }

  .phoneInput {
    max-width: 48%;
  }

  .nsInputBlock {
    position: relative;
    width: 100%;
    max-width: 48%;
  }

  .inputBig {
    max-width: 48%;
    margin-right: 20px;

    &.additionalSelectPayMentMethod {
      max-width: 48%;
    }

    &.inputAddress {
      max-width: 100%;
    }

    &:nth-child(2n) {
      margin-right: 0px;
    }
  }

  .phoneInput {
    max-width: 48%;
  }

  .saveBtn {
    width: 254px;
  }

  .addPayerBlock {
    flex-direction: row;
  }
}

@media screen and (min-width: 1281px) {
  .infoBtn {
    top: 33px;
  }
}
