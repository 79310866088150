.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
  gap: 25px;
}

.head_row {
  width: 100%;
  display: flex;
  gap: 30px;
  padding: 0 30px;
}

.table_head {
  font-size: 14px;
  color: var(--main-text);

  &:nth-child(1) {
    flex-basis: 10%;
  }
  &:nth-child(2) {
    flex-basis: 45%;
    word-wrap: break-word;
  }
  &:nth-child(3) {
    flex-basis: 30%;
    word-wrap: break-word;
  }
  &:nth-child(4) {
    flex-basis: 15%;
  }
}

@media screen and (min-width: 500px) {
  .list {
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  }
}

@media screen and (min-width: 1024px) {
  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;
  }
}
