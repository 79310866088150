.btn {
  position: relative;
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.switcher {
  height: 23px;
  width: 44px;
  .circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 23px;
    width: 44px;
    background-color: var(--switchers-bg);
    border-radius: 25px;
    box-shadow: var(--switchers-shadow);

    &.active {
      box-shadow: var(--switchers-shadow-active);
      background: var(--accent-gradient);
    }

    &::before {
      position: absolute;
      content: '';
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: var(--switchers-dot-bg);
      box-shadow: var(--switchers-dot-shadow);
      transform: translateX(0px);
      transition: 0.4s ease;
    }

    &.active {
      &::before {
        transition: 0.4s ease;
        transform: translateX(22px);
        box-shadow: var(--switchers-dot-shadow-active);
        background-color: #ffffff;
      }
    }
  }
}

.radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  box-shadow: 0px 1px 8px rgba(65, 38, 114, 0.15);
  border-radius: 50%;

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--accent-gradient);
    transform: scale(0);
    &.active {
      transition: 0.3s ease;
      transform: scale(1);
    }
  }
}
