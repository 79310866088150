.modalBg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 149;
  overflow-y: auto;

  &.closing {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  scrollbar-color: rgba(132, 87, 213, 1) rgba(161, 161, 161, 3%);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(132, 87, 213, 1);
  }
}

.modalBlock {
  background: var(--main-bgc);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  padding: 25px 0;
  margin: 20px auto;
  max-width: 690px;
  width: 90vw;
}

.modalCloseBlock {
  background: var(--main-bgc);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  padding: 28px 55px;
  margin: 20px auto;
  max-width: 360px;
  width: 100%;
}

.modalHeader {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.crossIcon {
  fill: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.formBlock {
  display: flex;
  flex-direction: column;

  &.promocodeBlock {
    padding-bottom: 15px;
    border-bottom: 1px solid #e1daea;
  }

  &.last {
    padding-bottom: 25px;
    border-bottom: 1px solid #e1daea;
  }
}

.formBlockTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: var(--main-text);
  // margin-left: 10px;
  margin: 20px 0;
}

.actLine {
  width: 100%;
  height: 1px;
  background: rgba(179, 169, 193, 0.51);
}

.fieldsGrid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 14px;
}

.formFieldsBlock {
  display: flex;
  flex-wrap: wrap;

  &.first {
    align-items: center;
  }

  &.promocode {
    margin-top: 20px;
  }
}

.btnBlock {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
}

.btnCloseBlock {
  display: flex;
  flex-direction: column;
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  height: 55px;
}

.cancel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-top: 22px;
  color: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.close {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-top: 22px;
  color: var(--main-text);
  cursor: pointer;
}

.select {
  max-width: 100%;
  // margin-top: 10px;

  // button {
  //   height: 50px;
  // }
}

.inputBig {
  max-width: 100%;
  padding-bottom: 0;
}

.error_message {
  margin-left: 2px;
  color: var(--error-color);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 3px;
}

.inputAmountBlock {
  display: flex;
  align-items: center;
  margin-top: 15px;
  flex-basis: 100%;
  position: relative;

  .input {
    width: 100%;
  }

  .currency {
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 1;
    font-weight: 400;
    font-size: 17px;
    line-height: 130.5%;
    color: var(--main-text);
  }

  .inputHeight {
    input {
      padding-right: 60px;
    }
  }
}

.paymentMethodBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin: 10px 0px 0 0;
  padding: 14px;
  box-shadow: var(--forms2_shadow);
  background-color: var(--forms_bgc_3);
  position: relative;
  max-height: 50px;
  height: 50px;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &.selected {
    span {
      max-width: 70%;
    }
    .iconCheck {
      display: flex;
    }
  }

  .iconCheck {
    stroke: var(--success-color);
    display: none;
    position: absolute;
    right: 16px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: var(--main-text);
    margin-left: 10px;

    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .balance {
    text-align: left;
    margin-left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: var(--transparent-color);
  }

  &:active {
    transform: scale(0.95);
  }
}

.infotext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
  margin-top: 20px;

  padding-bottom: 15px;
  border-bottom: 1px solid #e1daea;

  span {
    color: var(--error-color);
  }
}

.addNewPayerBtn {
  margin: 10px 15px 15px;
  display: flex;
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 70.9%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: var(--link-color);
  cursor: pointer;
}

.notAllowPayMethod {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.promocodeBtn {
  margin: 10px 15px 15px;
  display: flex;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 70.9%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: var(--main-text);
  cursor: pointer;

  &:disabled {
    color: var(--transparent-color);
    cursor: default;
  }
}

.offerBlock {
  display: flex;
  align-items: center;
  margin-top: 13px;
  // max-width: 270px;
}

.checkbox {
  margin-right: 10px;
}

.offerBlockText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-text);
}

.offerBlockLink {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
}

.inputPerson {
  max-width: 100%;
}

.priceBlock {
  margin-top: 9px;
  display: flex;
  gap: 5px;
}

.totalSum {
  margin-top: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.5%;
  color: var(--main-text);
  display: flex;
  flex-direction: column;

  span {
    margin-top: 9px;
  }

  span:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.infoBtn {
  position: relative;
  top: 0;
  margin-left: 7px;

  .descriptionBlock {
    top: auto;
    right: auto;
    left: 0;
  }
}

.sale55Promo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--transparent-color);
  margin-top: 11px;
  margin-left: 11px;
}

.scroll {
  overflow-y: auto;
  max-height: 277px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(132, 87, 213, 1);
  }
}

.itemsBlock {
  margin-top: 20px;
}

.closeText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;

  text-align: center;
  color: var(--main-text);
  margin-bottom: 20px;
}

.vds_wrapper {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 25px;
    background-image: linear-gradient(
      0deg,
      var(--main-bgc) 20%,
      rgba(0, 0, 0, 0.0001) 100%
    );
  }
}

.padding {
  padding: 0 22px;
}

.mb {
  margin-bottom: 10px;
}
.penalty_sum {
  font-size: 14px;
  font-weight: 300;
  color: var(--red-text);
  margin-top: 15px;
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.nsInputBlock {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.infoBtn {
  position: relative;
  top: 2px;
  margin-left: 7px;
  z-index: 2;
  cursor: pointer;

  &_address {
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 2;
    padding: 12px 12px 12px 0px;
    cursor: pointer;

    .descriptionBlock {
      right: 0;
    }
  }

  &:hover {
    svg path {
      fill: var(--accent-color-light);
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  bottom: 130%;
  width: 232px;
  z-index: 3;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

.inputAddressWrapp {
  padding-right: 35px;
}
.discItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-top: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600 !important;
  }
}

.additionalPayMethodBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.additionalSelectPayMentMethod {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .additionalPayMethodBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 14px;

    margin-top: 15px;
  }

  .additionalSelectPayMentMethod {
    margin-bottom: 0px;
  }
  // .formBlock {
  //   &:not(:first-child) {
  //     margin-top: 20px;
  //   }
  // }

  .fieldsGrid {
    grid-template-columns: 1fr 1fr;
  }

  .nsInputBlock {
    position: relative;
    width: 100%;
    // max-width: 46%;
  }

  .modalBg {
    align-items: center;
    height: 100vh;
  }

  .modalBlock {
    padding: 24px 0;
    margin: 0.5% auto;
  }

  .scroll {
    padding: 5px;
  }

  .padding {
    padding: 0 35px;
  }

  .paymentMethodBtn {
    max-width: 30%;
    flex-basis: 30%;
    margin: 10px 15px 0 0;
  }

  .inputAmountBlock {
    flex-basis: 30%;
  }

  .addNewPayerBtn {
    width: auto;
    margin: 13px 0 0 0;
  }

  .promocodeBtn {
    width: auto;
    margin: 13px 0 0 0;
  }

  .offerBlock {
    // margin-top: 33px;
  }

  .inputPerson {
    max-width: 311px;
    margin-right: 20px;
  }

  // .select {
  //   max-width: 46%;
  //   margin-top: 10px;
  //   margin-right: 20px;
  // }

  // .inputBig {
  //   max-width: 46%;
  //   margin-right: 20px;

  //   &.inputAddress {
  //     max-width: 100%;
  //   }

  //   &:nth-child(2n) {
  //     margin-right: 0px;
  //   }
  // }

  .btnBlock {
    flex-direction: row;
  }

  .btnCloseBlock {
    flex-direction: column;
  }

  .saveBtn {
    width: 254px;
  }

  .cancel {
    margin-top: 0px;
    margin-left: 20px;
  }
}

@media (max-width: 499px) {
  .infoBtn .descriptionBlock {
    top: auto;
    left: -700%;
  }
}

@media screen and (min-width: 1550px) {
  .modalBlock {
    padding: 24px 0;
    margin: 3% auto;
  }
}
