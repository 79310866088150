.messagesBlock {
  margin: 21px 0 6px;
  display: flex;
  flex-direction: column;
}

.archiveTicket {
  width: 100%;
  background: #fff2f2;
  padding: 20px 24px;
  border-radius: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--error-color);
  margin: 25px 0px;
  user-select: text;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1550px) {
}

@media screen and (min-width: 1918px) {
}
